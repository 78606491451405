// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alarm-out {
  display: flex;
  justify-content: center;
}
@media (width <= 1460px) {
  .alarm-out {
    flex-direction: column;
  }
}
.alarm-out .column .page-title {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.alarm-out .column .page-title h4 {
  margin-block-end: 5px;
}
.alarm-out .column .form-row:not(:last-child) {
  margin-bottom: 8px;
}
.alarm-out .column .form-row > span {
  color: #7e7e7e;
  flex: 1;
}
.alarm-out .column .form-row .cec-input-wrapper {
  flex: 1;
}
.alarm-out .column .form-row .cec-select {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/ActionSettings/AlarmOut/AlarmOut.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,uBAAA;AADD;AAGC;EAAA;IACC,sBAAA;EAAA;AACF;AANA;EAUG,gCAAA;EACA,mBAAA;AADH;AAVA;EAcI,qBAAA;AADJ;AAMG;EACC,kBAAA;AAJJ;AAhBA;EAwBI,cAAA;EACA,OAAA;AALJ;AApBA;EA6BI,OAAA;AANJ;AAvBA;EAiCI,OAAA;AAPJ","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.alarm-out {\n\tdisplay: flex;\n\tjustify-content: center;\n\n\t@media (width <= 1460px) {\n\t\tflex-direction: column;\n\t}\n\n\t.column {\n\t\t.page-title {\n\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\tmargin-bottom: 20px;\n\n\t\t\th4 {\n\t\t\t\tmargin-block-end: 5px;\n\t\t\t}\n\t\t}\n\n\t\t.form-row {\n\t\t\t&:not(:last-child) {\n\t\t\t\tmargin-bottom: 8px;\n\t\t\t}\n\n\t\t\t> span {\n\t\t\t\tcolor: @light-gray-2;\n\t\t\t\tflex: 1;\n\t\t\t}\n\n\t\t\t.cec-input-wrapper {\n\t\t\t\tflex: 1;\n\t\t\t}\n\n\t\t\t.cec-select {\n\t\t\t\tflex: 1;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
