import { combineSlices } from "@reduxjs/toolkit";
import userSettingsSlice from "Data/Redux/Slices/Settings/User";
import systemSettingsSlice from "Data/Redux/Slices/Settings/System";
import cameraSettingsSlice from "Data/Redux/Slices/Settings/Camera";
import networkSettingsSlice from "Data/Redux/Slices/Settings/Network";
import audioSettingsSlice from "Data/Redux/Slices/Settings/Audio";
import recordSettingsSlice from "Data/Redux/Slices/Settings/Record";
import eventSettingsSlice from "Data/Redux/Slices/Settings/Event";
import storageSettingsSlice from "Data/Redux/Slices/Settings/Storage";

export default combineSlices({
	audio: audioSettingsSlice,
	camera: cameraSettingsSlice,
	event: eventSettingsSlice,
	network: networkSettingsSlice,
	record: recordSettingsSlice,
	storage: storageSettingsSlice,
	system: systemSettingsSlice,
	user: userSettingsSlice
});
