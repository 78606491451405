// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-event-settings-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.system-event-settings-form .scrollable-content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 30px;
}
.system-event-settings-form .scrollable-content .event-note {
  background-color: #daeed2;
  border: 1px solid #d6e9c6;
  border-radius: 8px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 10px 15px;
  width: 100%;
}
.system-event-settings-form .scrollable-content .event-note > span {
  color: #428043;
  display: block;
}
.system-event-settings-form .scrollable-content .card {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
}
.system-event-settings-form .scrollable-content .card .page-title {
  border-bottom: 1px solid #7e7e7e;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}
.system-event-settings-form .scrollable-content .card .page-title h4 {
  margin-block-end: 5px;
}
.system-event-settings-form .scrollable-content .card .cec-checkbox-container {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/SystemEventSettings/SystemEventSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AADF;AAVA;EAcG,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AADH;AAnBA;EAuBI,cAAA;EACA,cAAA;AADJ;AAvBA;EA6BG,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;AAHH;AA9BA;EAoCI,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AAHJ;AApCA;EA0CK,qBAAA;AAHL;AAvCA;EA+CI,gBAAA;AALJ","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.system-event-settings-form {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.scrollable-content {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.event-note {\n\t\t\tbackground-color: #daeed2;\n\t\t\tborder: 1px solid #d6e9c6;\n\t\t\tborder-radius: 8px;\n\t\t\tfont-weight: 600;\n\t\t\tmargin-bottom: 20px;\n\t\t\tpadding: 10px 15px;\n\t\t\twidth: 100%;\n\n\t\t\t> span {\n\t\t\t\tcolor: #428043;\n\t\t\t\tdisplay: block;\n\t\t\t}\n\t\t}\n\n\t\t.card {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tpadding: 0 20px 20px;\n\n\t\t\t.page-title {\n\t\t\t\tborder-bottom: 1px solid @light-gray-2;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tmargin-bottom: 15px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.cec-checkbox-container {\n\t\t\t\tmargin-bottom: 0;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
