import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { ISnmpSettingsState, SnmpAuthenticationProtocol, SnmpPrivacyProtocol, SnmpType } from "Interfaces";

const initialState: ISnmpSettingsState = {
	snmpType: SnmpType.NONE,
	v2: {
		writeCommunity: "",
		readCommunity: "",
		trapAddress: "",
		trapCommunity: "",
		trapEnabled: false,
		trapOptions: {
			authFailure: false,
			networkConnection: false,
			otherOption: false
		}
	},
	v3: {
		trapAddress: "",
		trapCommunity: "",
		trapEnabled: false,
		trapOptions: {
			authFailure: false,
			networkConnection: false,
			otherOption: false
		},
		authPassword: "",
		authProtocol: SnmpAuthenticationProtocol.NONE,
		privacyPassword: "",
		privacyProtocol: SnmpPrivacyProtocol.NONE,
		username: ""
	}
};

export const setSnmpReducer = (state: ISnmpSettingsState, action: PayloadAction<ISnmpSettingsState>) => {
	return action.payload;
};

export const getSnmp = (state: ISnmpSettingsState) => {
	return state;
};

const snmpSlice = createAppSlice({
	name: "snmp",
	initialState,
	reducers: {
		setSnmpAction: setSnmpReducer
	},
	selectors: {
		selectSnmp: getSnmp
	}
});

export default snmpSlice;

export const { setSnmpAction } = snmpSlice.actions;
export const { selectSnmp } = snmpSlice.getSelectors<AppState>(state => state.settings.network.snmp);
