import { PayloadAction } from "@reduxjs/toolkit";
import { AlarmOutOperation, defaultAudioFiles, FlickerFrequency, Operation } from "Data/Objects/Event";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { produce } from "immer";
import { IActionState } from "Interfaces";

const initialState: IActionState = {
	alarmOut: {
		name: "A01",
		operation: AlarmOutOperation.ALARM_OUT,
		schedule: {
			always: false,
			weekday: false,
			weekend: false
		},
		type: Operation.NORMALLY_CLOSED,
		duration: 0
	},
	sendImage: {
		sdSettings: {
			receiver: "",
			frequency: 0,
			log: false,
			filePrefix: "",
			schedule: {
				always: false,
				weekday: false,
				weekend: false
			}
		},
		ftpSettings: {
			receiver: "",
			host: "",
			port: 21,
			basePath: "",
			idPassRequired: false,
			id: "",
			password: "",
			frequency: 0,
			log: false,
			filePrefix: "",
			schedule: {
				always: false,
				weekday: false,
				weekend: false
			}
		}
	},
	whiteLight: {
		alarmEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		motionEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		tamperEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		aiAnalytics: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		schedule: {
			always: false,
			weekday: false,
			weekend: false
		}
	},
	redAndBlueLight: {
		alarmEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		motionEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		tamperEvent: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		aiAnalytics: {
			flickerFrequency: FlickerFrequency.MEDIUM,
			duration: 10
		},
		schedule: {
			always: false,
			weekday: false,
			weekend: false
		}
	},
	audio: {
		audioFiles: defaultAudioFiles.map((file) => ({ name: file, fixed: true })),
		alarmEvent: {
			audio: "",
			count: 1
		},
		motionEvent: {
			audio: "",
			count: 1
		},
		tamperEvent: {
			audio: "",
			count: 1
		},
		aiAnalytics: 1,
		schedule: {
			always: false,
			weekday: false,
			weekend: false
		}
	}
};

const setActionSettingsReducer = (state: IActionState, action: PayloadAction<IActionState>) => {
	return produce(action.payload, draft => {
		delete draft.sendImage.ftpSettings.password;
	});
};

const getActionSettings = (state: IActionState) => {
	return produce(state, draft => {
		draft.sendImage.ftpSettings.password = "";
	});
};

const actionSlice = createAppSlice({
	initialState,
	name: "action",
	reducers: {
		setActionSettingsAction: setActionSettingsReducer
	},
	selectors: {
		selectActionSettings: getActionSettings
	}
});

export default actionSlice;

export const { setActionSettingsAction } = actionSlice.actions;
export const { selectActionSettings } = actionSlice.getSelectors<AppState>(state => state.settings.event.action);
