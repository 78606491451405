import { faLanguage } from "@fortawesome/pro-regular-svg-icons/faLanguage";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
import { faTv } from "@fortawesome/pro-regular-svg-icons/faTv";
import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket";
import { faClapperboardPlay } from "@fortawesome/pro-regular-svg-icons/faClapperboardPlay";
import { faPlay } from "@fortawesome/pro-regular-svg-icons/faPlay";
import { faCamera } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faExpand } from "@fortawesome/pro-regular-svg-icons/faExpand";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faSquareInfo } from "@fortawesome/pro-regular-svg-icons/faSquareInfo";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";

export default [
	faLanguage, faCircleQuestion, faTv, faArrowRightFromBracket, faClapperboardPlay, faPlay, faCamera, faExpand,
	faDownload, faSquareInfo, faBars, faCircleInfo
];