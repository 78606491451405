// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.light-settings {
  display: flex;
  justify-content: center;
}
@media (width <= 1460px) {
  .light-settings {
    flex-direction: column;
  }
}
.light-settings .column .form-row:not(:last-child) {
  margin-bottom: 8px;
}
.light-settings .column .form-row > span {
  color: #7e7e7e;
  flex: 1;
}
.light-settings .column .form-row > *:nth-child(3) {
  flex: 0.25;
}
.light-settings .column .form-row > *:nth-child(2) {
  flex: 1;
}
.light-settings .column .form-row > *:last-child {
  margin-left: 20px;
}
.light-settings .column .header-row {
  font-weight: bold;
  margin-top: 1.33em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/ActionSettings/ActionLightSettings/ActionLightSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,uBAAA;AADD;AAGC;EAAA;IACC,sBAAA;EAAA;AACF;AAIG;EACC,kBAAA;AAFJ;AATA;EAeI,cAAA;EACA,OAAA;AAHJ;AAbA;EAoBI,UAAA;AAJJ;AAhBA;EAwBI,OAAA;AALJ;AAnBA;EA4BI,iBAAA;AANJ;AAtBA;EAiCG,iBAAA;EACA,kBAAA;AARH","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.light-settings {\n\tdisplay: flex;\n\tjustify-content: center;\n\n\t@media (width <= 1460px) {\n\t\tflex-direction: column;\n\t}\n\n\t.column {\n\t\t.form-row {\n\t\t\t&:not(:last-child) {\n\t\t\t\tmargin-bottom: 8px;\n\t\t\t}\n\n\t\t\t> span {\n\t\t\t\tcolor: @light-gray-2;\n\t\t\t\tflex: 1;\n\t\t\t}\n\n\t\t\t>*:nth-child(3) {\n\t\t\t\tflex: 0.25;\n\t\t\t}\n\n\t\t\t>*:nth-child(2) {\n\t\t\t\tflex: 1;\n\t\t\t}\n\n\t\t\t>*:last-child {\n\t\t\t\tmargin-left: 20px;\n\t\t\t}\n\t\t}\n\n\t\t.header-row {\n\t\t\tfont-weight: bold;\n\t\t\tmargin-top: 1.33em;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
