import { FormEvent, Fragment, memo, useState } from "react";
import "Components/ConfigLayout/Event/NotificationSettings/NotificationSettings.less";
import {
	Button, ButtonTypes, Checkbox, ConfirmModal, Select, Table
} from "@clintonelec/react-storybook";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import {
	selectEventNotificationSettings, setEventNotificationAction
} from "Data/Redux/Slices/Settings/Event/Notification";
import { IEventNotificationState } from "Interfaces";
import { produce } from "immer";
import { isArray, isEqual, mergeWith } from "lodash";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { emailFrequencyOptions, scheduleNote } from "Data/Objects/Event";
import EmailEntryModal from "Components/ConfigLayout/Event/EmailEntryModal";

export interface IEventNotificationFormFields extends HTMLFormElement {
	emailList: HTMLInputElement;
	minimumEmailFrequency: HTMLInputElement;
	includeSnapshotOption: HTMLInputElement;
	presetSchedule: RadioNodeList;
}

const tableColumns = [
	{
		title: "E-Mail",
		dataIndex: "email",
		key: "email",
		sortable: false
	},
	{
		title: "Actions",
		dataIndex: "actions",
		key: "actions",
		sortable: false
	}
];

function NotificationSettings() {
	const dispatch = useAppDispatch();
	const eventNotificationSettings = useAppSelector(selectEventNotificationSettings);
	const [ eventNotificationSettingsDiff, setEventNotificationSettingsDiff ] =
		useState<Partial<IEventNotificationState>>({});

	const mergeSettingsArrayDiff = (objValue: unknown, srcValue: unknown) => {
		if (isArray(objValue)) {
			return srcValue;
		}
	};

	const localEventNotificationSettings = produce(eventNotificationSettings, (draft) => {
		mergeWith(draft, eventNotificationSettingsDiff, mergeSettingsArrayDiff);
	});

	const setEventNotificationSettings = () => {
		dispatch(setEventNotificationAction(localEventNotificationSettings));
	};

	const emailEnabled = (email: string) => {
		return Object.keys(localEventNotificationSettings.emailList).every(entry => {
			return localEventNotificationSettings.emailList[entry] !== email;
		});
	};

	const getEmailList = () => {
		return localEventNotificationSettings.emailList.map((email, index) => ({
			key:`${ index }`,
			email: email,
			actions: actionButtons(email)
		}));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setEventNotificationSettings();
			setEventNotificationSettingsDiff({});
		}
	};

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setEventNotificationSettingsDiff({});
	};

	const handleFormChange = (event: FormEvent<IEventNotificationFormFields>) => {
		const { presetSchedule, includeSnapshotOption } = event.currentTarget;

		setEventNotificationSettingsDiff(produce(localEventNotificationSettings, (draft) => {
			draft.includeSnapshotOption = includeSnapshotOption?.checked;
			Object.keys(localEventNotificationSettings.presetSchedule).forEach(schedule => {
				presetSchedule.forEach((input: HTMLInputElement) => {
					if (input.id === schedule) {
						draft.presetSchedule[ schedule ] = input.checked;
					}
				});
			});
		}));
	};

	const handleAddEmailEntry = (email: string) => {
		setEventNotificationSettingsDiff(produce(localEventNotificationSettings, draft => {
			draft.emailList.push(email);
		}));
	};

	const handleDeleteEmailEntry = (email: string) => () => {
		setEventNotificationSettingsDiff(produce(localEventNotificationSettings, draft => {
			draft.emailList = localEventNotificationSettings.emailList.filter(entry => entry !== email);
		}));
	};

	const handleUpdateEmailEntry = (email: string, prevEmail: string) => {
		const indexMatch = localEventNotificationSettings.emailList.findIndex(entry => entry === prevEmail);

		setEventNotificationSettingsDiff(produce(localEventNotificationSettings, draft => {
			draft.emailList[ indexMatch ] = email;
		}));
	};

	const handleSelectEmailFrequency = (value: string) => {
		setEventNotificationSettingsDiff(produce(localEventNotificationSettings, draft => {
			draft.minimumEmailFrequency = +value;
		}));
	};

	const actionButtons = (email: string) => (
		<div className="email-list-actions">
			<EmailEntryModal
				emailEnabled={ emailEnabled }
				handleEmailEntryCallback={ handleUpdateEmailEntry }
				oldEmail={ email }
			>
				<Button
					className="edit-button"
					ghost
					htmlType="button"
					icon={ { name: "pencil" } }
					type={ ButtonTypes.SECONDARY }
				/>
			</EmailEntryModal>
			<ConfirmModal
				cancelButton={ { text: "Cancel" } }
				okButton={ { text: "Confirm", onClick: handleDeleteEmailEntry(email) } }
				title="Delete E-Mail"
				modalContent="Are you sure you want to remove this E-Mail?"
			>
				<Button
					ghost
					htmlType="button"
					icon={ { name: "trash" } }
					type={ ButtonTypes.DANGER }
				/>
			</ConfirmModal>
		</div>
	);

	const renderRecordingSchedule = () => {
		return Object.entries(localEventNotificationSettings.presetSchedule).map((schedule, index) => {
			const [ scheduleName, checked ] = schedule;

			return (
				// eslint-disable-next-line react/no-array-index-key
				<Fragment key={ `presetSchedule-${ index }` }>
					<Checkbox
						checked={ checked }
						id={ scheduleName }
						name="presetSchedule"
					>
						{ scheduleName }
					</Checkbox>
				</Fragment>
			);
		});
	};

	return (
		<form
			className="event-notification-form"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleSubmit }
			onChange={ handleFormChange }
		>
			<div className="scrollable-container">
				<div className="card">
					<div className="header">
						<h4>E-Mail List</h4>
					</div>
					<div className="email-list-content">
						<EmailEntryModal
							handleEmailEntryCallback={ handleAddEmailEntry }
							emailEnabled={ emailEnabled }
						>
							<Button
								className="add-email-button"
								htmlType="button"
								icon={ { name: "plus" } }
								type={ ButtonTypes.TERTIARY }
							>
								Add E-Mail
							</Button>
						</EmailEntryModal>
						<Table
							data={ getEmailList() }
							columns={ tableColumns }
						/>
					</div>
				</div>
				<div className="card">
					<div className="header">
						<h4>Options</h4>
					</div>
					<div className="form-row">
						<span>Minimum E-Mail Frequency</span>
						<Select
							allowClear={ false }
							disabled={ false }
							name="minimumEmailFrequency"
							onSelect={ handleSelectEmailFrequency }
							options={ emailFrequencyOptions }
							value={ localEventNotificationSettings.minimumEmailFrequency }
						/>
					</div>
					<div className="form-row">
						<span>Include Snapshot</span>
						<div className="snapshot-checkbox-wrapper">
							<Checkbox
								checked={ localEventNotificationSettings.includeSnapshotOption }
								name="includeSnapshotOption"
								value="snapshotOptionSetting"
							/>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="header">
						<h4>Schedule</h4>
					</div>
					<div className="form-row">
						<div className="note">
							<span>Note - <Link to="/setup/system/schedule">{ scheduleNote }</Link></span>
						</div>
					</div>
					<div className="form-row">
						<div className="schedule-checkbox-wrapper">
							{ renderRecordingSchedule() }
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localEventNotificationSettings, eventNotificationSettings) } />
		</form>
	);
}

export default memo(NotificationSettings);
