import { Checkbox, Input, RangeSlider } from "@clintonelec/react-storybook";
import { MotionMode, MotionObject } from "Data/Objects/Camera";
import { produce } from "immer";
import { IMotionCameraState } from "Interfaces";
import { Dispatch, memo, SetStateAction } from "react";

interface ISmartMotionControlsProps {
	localMotionSettings: IMotionCameraState;
	setLocalMotionSettings: Dispatch<SetStateAction<IMotionCameraState>>;
}

function SmartMotionControls(props: ISmartMotionControlsProps) {
	const {
		localMotionSettings,
		localMotionSettings: {
			motionMode, selectedMotionObjects, personConfidenceLevel, bikeConfidenceLevel, vehicleConfidenceLevel
		},
		setLocalMotionSettings
	} = props;

	const smartMotionDisabled = motionMode === MotionMode.VIDEO;
	const smartMotionValidation = selectedMotionObjects.length === 0
		? "smart-motion-validation"
		: "smart-motion-validation-disabled";

	const handleUpdatePersonConfidence = (value: string) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.personConfidenceLevel = +value;
		}));
	};

	const handlePersonConfidenceOnChange = (value: number) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.personConfidenceLevel = value;
		}));
	};

	const handleUpdateVehicleConfidence = (value: string) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.vehicleConfidenceLevel = +value;
		}));
	};

	const handleVehicleConfidenceOnChange = (value: number) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.vehicleConfidenceLevel = value;
		}));
	};

	const handleUpdateBikeConfidence = (value: string) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.bikeConfidenceLevel = +value;
		}));
	};

	const handleBikeConfidenceOnChange = (value: number) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.bikeConfidenceLevel = value;
		}));
	};

	return (
		<div className="motion-setting-row smart-motion-settings-row">
			<span>Smart Motion Object</span>
			<div className="smart-motion-object-group">
				<div className="smart-motion-object">
					<Checkbox
						disabled={ smartMotionDisabled }
						name="personConfidence"
						checked={ selectedMotionObjects.includes(MotionObject.PERSON) }
					>
						<span>Person</span>
					</Checkbox>
					<RangeSlider
						disabled={ smartMotionDisabled }
						onAfterChange={ handlePersonConfidenceOnChange }
						value={ personConfidenceLevel }
					/>
					<Input
						disabled={ smartMotionDisabled }
						hideCharMessage
						onUpdate={ handleUpdatePersonConfidence }
						min={ 0 }
						max={ 100 }
						type="number"
						value={ personConfidenceLevel }
					/>
				</div>
				<div className="smart-motion-object">
					<Checkbox
						disabled={ smartMotionDisabled }
						name="vehicleConfidence"
						checked={ selectedMotionObjects.includes(MotionObject.VEHICLE) }
					>
						<span>Vehicle</span>
					</Checkbox>
					<RangeSlider
						disabled={ smartMotionDisabled }
						onAfterChange={ handleVehicleConfidenceOnChange }
						value={ vehicleConfidenceLevel }
					/>
					<Input
						disabled={ smartMotionDisabled }
						hideCharMessage
						onUpdate={ handleUpdateVehicleConfidence }
						min={ 0 }
						max={ 100 }
						type="number"
						value={ vehicleConfidenceLevel }
					/>
				</div>
				<div className="smart-motion-object">
					<Checkbox
						disabled={ smartMotionDisabled }
						name="bikeConfidence"
						checked={ selectedMotionObjects.includes(MotionObject.BIKE) }
					>
						<span>Bike</span>
					</Checkbox>
					<RangeSlider
						disabled={ smartMotionDisabled }
						onAfterChange={ handleBikeConfidenceOnChange }
						value={ bikeConfidenceLevel }
					/>
					<Input
						disabled={ smartMotionDisabled }
						hideCharMessage
						onUpdate={ handleUpdateBikeConfidence }
						min={ 0 }
						max={ 100 }
						type="number"
						value={ bikeConfidenceLevel }
					/>
				</div>
				<span className={ smartMotionValidation }>
					At least one object must be selected.
				</span>
			</div>
		</div>
	);
}

export default memo(SmartMotionControls);
