import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/SystemEventSettings/SystemEventSettings.less";
import { Checkbox, IColumn, Table } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { selectSystemEvent, setSystemEventAction } from "Data/Redux/Slices/Settings/Event/System";

const systemEventColumns: IColumn[] = [
	{
		title: "Event Parameter",
		dataIndex: "eventParameter",
		key: "eventParameter",
		sortable: false
	},
	{
		dataIndex: "alarmOut",
		title: "Alarm Out",
		key: "alarmOut",
		sortable: false
	},
	{
		dataIndex: "sendImage",
		title: "Send Image",
		key: "sendImage",
		sortable: false
	},
	{
		dataIndex: "email",
		title: "Email",
		key: "email",
		sortable: false
	}
];

interface ISystemEventForm extends HTMLFormElement {
	alarmOut: HTMLInputElement;
	sendImage: HTMLInputElement;
	email: HTMLInputElement;
}

function SystemEventSettings() {
	const dispatch = useAppDispatch();
	const systemEventSettings = useAppSelector(selectSystemEvent);
	const [ localSystemEventSettings, setLocalSystemEventSettings ] = useState(systemEventSettings);
	const setSystemEvent = () => dispatch(setSystemEventAction(localSystemEventSettings));

	const handleFormChange = (event: FormEvent<ISystemEventForm>) => {
		const {
			alarmOut: { checked: newAlarmOut },
			sendImage: { checked: newSendImage },
			email: { checked: newEmail }
		} = event.currentTarget;

		const newLocalSettings = produce(localSystemEventSettings, draft => {
			draft.alarmOut = newAlarmOut;
			draft.sendImage = newSendImage;
			draft.email = newEmail;
		});

		setLocalSystemEventSettings(newLocalSettings);
	};

	const handleFormSubmit = (event: FormEvent<ISystemEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setSystemEvent();
		}
	};

	const handleFormReset = (event: FormEvent<ISystemEventForm>) => {
		event.preventDefault();

		setLocalSystemEventSettings(systemEventSettings);
	};

	const tableData = [
		{
			key: "systemEvent",
			eventParameter: "Booting Event",
			alarmOut: <Checkbox name="alarmOut" checked={ localSystemEventSettings.alarmOut } />,
			sendImage: <Checkbox name="sendImage" checked={ localSystemEventSettings.sendImage } />,
			email: <Checkbox name="email" checked={ localSystemEventSettings.email } />
		}
	];

	return (
		<form
			className="system-event-settings-form"
			noValidate
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-content">
				<div className="event-note">
					<span>
						Notification - Actions and notifications require detailed settings on each setting page to work.
					</span>
				</div>
				<div className="card">
					<div className="page-title">
						<h4>System Event Settings</h4>
					</div>
					<Table
						columns={ systemEventColumns }
						data={ tableData }
					/>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localSystemEventSettings, systemEventSettings) } />
		</form>
	);
}

export default memo(SystemEventSettings);
