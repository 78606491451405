import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_GROUP_AUTHORITY } from "Data/Dummy/User";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IGroupAuthorityState } from "Interfaces";

const initialState = DUMMY_GROUP_AUTHORITY;

// #region Reducers

export const setGroupAuthorityReducer = (state: IGroupAuthorityState, action: PayloadAction<IGroupAuthorityState>) => {
	return action.payload;
};

// #endregion

// #region Selectors

export const getGroupAuthority = (state: IGroupAuthorityState) => {
	return state;
};

// #endregion

const authoritySlice = createAppSlice({
	name: "authority",
	initialState,
	reducers: {
		setGroupAuthorityAction: setGroupAuthorityReducer
	},
	selectors: {
		selectGroupAuthority: getGroupAuthority
	}
});

export default authoritySlice;

export const {
	setGroupAuthorityAction
} = authoritySlice.actions;

export const {
	selectGroupAuthority
} = authoritySlice.getSelectors<AppState>(state => state.settings.user.authority);
