import { produce } from "immer";
import { isEqual } from "lodash";
import { IPoint } from "Interfaces";
import { useDispatch } from "react-redux";
import { FormEvent, memo, useState } from "react";
import { useAppSelector } from "Data/Redux/Store";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import InteractivePolygon from "Components/Global/InteractivePolygon";
import "Components/ConfigLayout/Camera/MaskSettings/MaskSettings.less";
import MaskedArea from "Components/ConfigLayout/Camera/MaskSettings/MaskedArea";
import { selectMaskSettings, setMaskSettingsAction } from "Data/Redux/Slices/Settings/Camera/Mask";
import { AspectMaintainer, Button, ButtonTypes, ISelectOption, Select, Switch } from "@clintonelec/react-storybook";

const colorOptions: ISelectOption[] =
	[ "Black", "White", "Gray", "Yellow", "Red", "Blue", "Green" ].map(color => ({
		label: color,
		value: color.toLowerCase()
	}));

function MaskSettings() {
	const dispatch = useDispatch();
	const maskSettings = useAppSelector(selectMaskSettings);
	const [ localMaskSettings, setLocalMaskSettings ] = useState(maskSettings);
	const [ selectedMask, setSelectedMask ] = useState<number>(null);
	const setMaskSettings = () => dispatch(setMaskSettingsAction(localMaskSettings));
	const allZonesEmpty = localMaskSettings.zones.every(zone => zone.length === 0);

	const handleMaskColorSelect = (value: string) => {
		const newLocalState = produce(localMaskSettings, draft => {
			draft.maskColor = value;
		});

		setLocalMaskSettings(newLocalState);
	};

	const handleZoneButtonClick = (zoneIndex: number) => () => {
		setSelectedMask(selectedMask === zoneIndex ? null : zoneIndex);
	};

	const handlePointsUpdate = (newPoints: IPoint[]) => {
		const newLocalState = produce(localMaskSettings, draft => {
			draft.zones[ selectedMask ] = newPoints;
		});

		setLocalMaskSettings(newLocalState);
	};

	const handleClearActiveZone = () => {
		const newLocalState = produce(localMaskSettings, draft => {
			draft.zones[ selectedMask ] = [];
		});

		setLocalMaskSettings(newLocalState);
		setSelectedMask(null);
	};

	const handleClearAllZones = () => {
		const newLocalState = produce(localMaskSettings, draft => {
			draft.zones = [ [], [], [], [], [], [], [], [] ];
		});

		setLocalMaskSettings(newLocalState);
		setSelectedMask(null);
	};

	const handleFormReset = (event: FormEvent) => {
		event.preventDefault();

		setLocalMaskSettings(maskSettings);
		setSelectedMask(null);
	};

	const handleFormSubmit = (event: FormEvent) => {
		event.preventDefault();

		setMaskSettings();
		setSelectedMask(null);
	};

	const handlePrivacyMaskEnabledChange = (newEnabled: boolean) => {
		const newLocalState = produce(localMaskSettings, draft => {
			draft.maskEnabled = newEnabled;
		});

		setLocalMaskSettings(newLocalState);
		setSelectedMask(null);
	};

	const renderMaskButtons = () => Array.from(Array(8)).map((current, index) => {
		const isButtonSelected = selectedMask === index;
		const filled = localMaskSettings.zones[ index ]?.length > 0;

		return (
			<Button
				// eslint-disable-next-line react/no-array-index-key
				key={ `zone-button-${ index }` }
				className="zone-button"
				disabled={ !localMaskSettings.maskEnabled }
				ghost={ !isButtonSelected && !filled }
				htmlType="button"
				onClick={ handleZoneButtonClick(index) }
				type={ isButtonSelected ? ButtonTypes.TERTIARY : ButtonTypes.SECONDARY }
			>
				Zone { index + 1 }
			</Button>
		);
	});

	const renderInteractivePolygon = () => {
		if (selectedMask === null) {
			return;
		}

		return (
			<InteractivePolygon
				points={ localMaskSettings.zones[ selectedMask ] }
				onUpdate={ handlePointsUpdate }
			/>
		);
	};

	const renderMaskedArea = () => {
		if (!maskSettings.maskEnabled) {
			return;
		}

		return (
			<MaskedArea
				color={ localMaskSettings.maskColor }
				zones={ maskSettings.zones }
			/>
		);
	};

	return (
		<form
			className="mask-settings-form"
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="mask-settings-container">
					<div className="mask-settings">
						<div className="card">
							<div className="card-title">
								<h4>Privacy Mask</h4>
							</div>
							<div className="content">
								<div className="left-content">
									<div className="form-row">
										<Switch
											checked={ localMaskSettings.maskEnabled }
											className="mask-switch"
											onChange={ handlePrivacyMaskEnabledChange }
										/>
										<span>Enable Privacy Mask</span>
									</div>
									<div className="camera-container">
										<AspectMaintainer>
											<div className="camera-snapshot">
												{ renderMaskedArea() }
												{ renderInteractivePolygon() }
											</div>
										</AspectMaintainer>
									</div>
									<div className="controls-container">
										<div className="mask-buttons">
											{ renderMaskButtons() }
										</div>
										<Button
											className="zone-action-button"
											disabled={ !localMaskSettings.maskEnabled || selectedMask === null }
											ghost
											htmlType="button"
											onClick={ handleClearActiveZone }
											type={ ButtonTypes.SECONDARY }
										>
											Clear Active Zone
										</Button>
										<Button
											className="zone-action-button"
											disabled={ !localMaskSettings.maskEnabled || allZonesEmpty }
											type={ ButtonTypes.SECONDARY }
											ghost
											htmlType="button"
											onClick={ handleClearAllZones }
										>
											Clear All Zones
										</Button>
									</div>
								</div>
								<div className="right-content">
									<div className="form-row mask-color">
										<span>Mask Color</span>
										<Select
											allowClear={ false }
											className="color-select"
											onSelect={ handleMaskColorSelect }
											options={ colorOptions }
											value={ localMaskSettings.maskColor }
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(maskSettings, localMaskSettings) } />
		</form>
	);
}

export default memo (MaskSettings);
