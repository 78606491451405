import { PayloadAction } from "@reduxjs/toolkit";
import { IEmbeddedOsdSettingsState } from "Interfaces";
import { createAppSlice } from "Data/Redux/Store";
import { AppState } from "Data/Redux/Reducers";
import { Colors, DeviceNamePosition } from "Data/Objects/Camera";

const initialState: IEmbeddedOsdSettingsState = {
	cameraInfo: {
		resolution: false,
		dateTime: false,
		deviceName: DeviceNamePosition.OFF
	},
	ai: {
		displayMode: false,
		objectColor: Colors.BLACK,
		ruleColor: Colors.BLUE,
		eventColor: Colors.CYAN
	}
};

export const setEmbeddedOsdReducer = (
	state: IEmbeddedOsdSettingsState,
	action: PayloadAction<IEmbeddedOsdSettingsState>
) => {
	return action.payload;
};

export const getEmbeddedOsd = (state: IEmbeddedOsdSettingsState) => {
	return state;
};

const embeddedOsdSlice = createAppSlice({
	name: "embeddedOsd",
	initialState,
	reducers: {
		setEmbeddedOsdAction: setEmbeddedOsdReducer
	},
	selectors: {
		selectEmbeddedOsd: getEmbeddedOsd
	}
});

export default embeddedOsdSlice;

export const { setEmbeddedOsdAction } = embeddedOsdSlice.actions;
export const { selectEmbeddedOsd } = embeddedOsdSlice.getSelectors<AppState>(
	state => state.settings.camera.embeddedOsd
);
