import { ISelectOption, RangeSlider, Select } from "@clintonelec/react-storybook";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import "Components/ConfigLayout/Audio/AudioSettings/AudioSettings.less";
import { AudioCodec } from "Data/Objects/Audio";
import { selectAudio, setAudioAction } from "Data/Redux/Slices/Settings/Audio/Audio";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { produce } from "immer";
import { IAudioSettingsState } from "Interfaces";
import { isEqual, merge } from "lodash";
import { FormEvent, memo, useState } from "react";

const enableAudioOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "On",
		value: "ON"
	}
];

const audioCodecOptions: ISelectOption[] = [
	{
		label: "G.711 u-law 8KHz",
		value: AudioCodec.G711
	}
];

function AudioSettings() {
	const dispatch = useAppDispatch();
	const audioSettings = useAppSelector(selectAudio);
	const [ audioSettingsDiff, setAudioSettingsDiff ] = useState<Partial<IAudioSettingsState>>({});

	const setAudioSettings = (newSettings: IAudioSettingsState) => {
		dispatch(setAudioAction(newSettings));
	};

	const localAudioSettings = produce(audioSettings, draft => {
		merge(draft, audioSettingsDiff);
	});

	const { enableAudio, audioCodec, micVolume, speakerVolume } = localAudioSettings;
	const disabled = isEqual(localAudioSettings, audioSettings);
	const sliderClassName = `audio-slider ${ !enableAudio ? "disabled" : "" }`;

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setAudioSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setAudioSettings(localAudioSettings);
		setAudioSettingsDiff({});
	};

	const selectEnableAudio = (value: string) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.enableAudio = value === "ON";
		}));
	};

	const selectAudioCodec = (value: string) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.audioCodec = value as AudioCodec;
		}));
	};

	const updateMicVolume = (value: number) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.micVolume = value * 5;
		}));
	};

	const updateSpeakerVolume = (value: number) => {
		setAudioSettingsDiff(produce(localAudioSettings, draft => {
			draft.speakerVolume = value * 5;
		}));
	};

	return (
		<form className="audio-settings-form" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="container">
				<div className="card">
					<div className="header">
						<h4>Audio</h4>
					</div>
					<div className="form-row">
						<span>Enable Audio</span>
						<Select
							allowClear={ false }
							className="audio-select"
							onSelect={ selectEnableAudio }
							options={ enableAudioOptions }
							value={ enableAudio ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<span>Audio Codec</span>
						<Select
							allowClear={ false }
							className="audio-select"
							disabled={ !enableAudio }
							onSelect={ selectAudioCodec }
							options={ audioCodecOptions }
							value={ audioCodec }
						/>
					</div>
					<div className="form-row">
						<span>Mic Volume</span>
						<div className={ sliderClassName }>
							<RangeSlider
								disabled={ !enableAudio }
								max={ 20 }
								min={ 0 }
								onAfterChange={ updateMicVolume }
								value={ micVolume / 5 }
							/>
						</div>
					</div>
					<div className="form-row">
						<span>Speaker Volume</span>
						<div className={ sliderClassName }>
							<RangeSlider
								disabled={ !enableAudio }
								max={ 20 }
								min={ 0 }
								onAfterChange={ updateSpeakerVolume }
								value={ speakerVolume / 5 }
							/>
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(AudioSettings);
