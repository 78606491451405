import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Event/ActionSettings/Audio/Audio.less";
import {
	Button, ButtonTypes, Checkbox, ConfirmModal, Input, ISelectOption, Popover, Select
} from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IActionState, IAudioFile } from "Interfaces";
import { Link } from "react-router-dom";
import { defaultAudioFiles } from "Data/Objects/Event";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";
import AddAudioModal from "Components/ConfigLayout/Event/ActionSettings/AddAudioModal";

const audioOptions: ISelectOption[] = defaultAudioFiles.map((file) => ({
	label: file,
	value: file
}));

interface IAudioProps {
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function Audio(props: IAudioProps) {
	const { localActionState } = props;
	const [ localActionSettings, setLocalActionSettings ] = localActionState;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const updateAlarmAudio = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.alarmEvent.audio = value;
		}));
	};

	const updateMotionAudio = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.motionEvent.audio = value;
		}));
	};

	const updateTamperAudio = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.tamperEvent.audio = value;
		}));
	};

	const updateAlarmCount = (count: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.alarmEvent.count = +count;
		}));
	};

	const updateMotionCount = (count: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.motionEvent.count = +count;
		}));
	};

	const updateTamperCount = (count: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.tamperEvent.count = +count;
		}));
	};

	const updateAiAnalyticsCount = (count: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.aiAnalytics = +count;
		}));
	};

	const updateScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const deleteAudio = (index: number) => () => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.audio.audioFiles.splice(index, 1);
		}));
	};

	const renderSchedule = (schedule: [ string, boolean ]) => {
		const [ scheduleName, checked ] = schedule;

		return (
			<div className="checkbox-wrapper" key={ scheduleName }>
				<Checkbox
					checked={ checked }
					onChange={ updateScheduleChecked(scheduleName) }
				/>
				<label>{ scheduleName }</label>
			</div>
		);
	};

	const renderAudioListItem = (audioFile: IAudioFile, index: number) => {
		const disabledClass = audioFile.fixed ? "list-icon disabled" : "list-icon";
		const editIcon = audioFile.fixed
			? (
				<Icon
					className={ disabledClass }
					name="pencil"
					iconWeight={ IconWeight.SOLID }
					size={ IconSize.SMALLEST }
				/>
			)
			: (
				<AddAudioModal localActionState={ localActionState } index={ index }>
					<Icon
						className={ disabledClass }
						name="pencil"
						iconWeight={ IconWeight.SOLID }
						size={ IconSize.SMALLEST }
					/>
				</AddAudioModal>
			);

		const deleteIcon = audioFile.fixed
			? (
				<Icon
					className={ disabledClass }
					name="trash"
					iconWeight={ IconWeight.SOLID }
					size={ IconSize.SMALLEST }
				/>
			)
			: (
				<ConfirmModal
					cancelButton={ { text: "Cancel" } }
					modalContent="Are you sure you want delete this audio?"
					title="Delete Audio"
					okButton={ { text: "Confirm", onClick: deleteAudio(index) } }
				>
					<Icon
						className={ disabledClass }
						name="trash"
						iconWeight={ IconWeight.SOLID }
						size={ IconSize.SMALLEST }
					/>
				</ConfirmModal>
			);

		return (
			<div className="audio-list-item" key={ index }>
				<Icon
					className="list-icon"
					name="play"
					iconWeight={ IconWeight.SOLID }
					size={ IconSize.SMALLEST }
				/>
				<span>{ audioFile.name }</span>
				<Icon
					className="list-icon"
					name="download"
					iconWeight={ IconWeight.SOLID }
					size={ IconSize.SMALLEST }
				/>
				{ editIcon }
				{ deleteIcon }
			</div>
		);
	};

	return (
		<div className="audio">
			<div className="column">
				<AddAudioModal localActionState={ localActionState }>
					<Button className="add-audio" type={ ButtonTypes.TERTIARY }>Add Audio</Button>
				</AddAudioModal>
				<div className="audio-list">
					{ localActionSettings.audio.audioFiles.map(renderAudioListItem) }
				</div>
			</div>
			<div className="column">
				<div className="form-row header-row">
					<span>Event Type</span>
					<span>Audio File</span>
					<span>Count</span>
				</div>
				<div className="form-row">
					<span>Alarm Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateAlarmAudio }
						options={ audioOptions }
						value={ localActionSettings.audio.alarmEvent.audio }
					/>
					<Input
						hideCharMessage={ true }
						max={ 10 }
						min={ 1 }
						onUpdate={ updateAlarmCount }
						type="number"
						value={ localActionSettings.audio.alarmEvent.count }
					/>
				</div>
				<div className="form-row">
					<span>Motion Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateMotionAudio }
						options={ audioOptions }
						value={ localActionSettings.audio.motionEvent.audio }
					/>
					<Input
						hideCharMessage={ true }
						max={ 10 }
						min={ 1 }
						onUpdate={ updateMotionCount }
						type="number"
						value={ localActionSettings.audio.motionEvent.count }
					/>
				</div>
				<div className="form-row">
					<span>Tamper Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateTamperAudio }
						options={ audioOptions }
						value={ localActionSettings.audio.tamperEvent.audio }
					/>
					<Input
						hideCharMessage={ true }
						max={ 10 }
						min={ 1 }
						onUpdate={ updateTamperCount }
						type="number"
						value={ localActionSettings.audio.tamperEvent.count }
					/>
				</div>
				<div className="form-row">
					<span>AI Analytics</span>
					<Link to="/setup/camera/analytics">Go to AI Analytics to set Audio.</Link>
					<Input
						hideCharMessage={ true }
						max={ 10 }
						min={ 1 }
						onUpdate={ updateAiAnalyticsCount }
						type="number"
						value={ localActionSettings.audio.aiAnalytics }
					/>
				</div>
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ Object.entries(localActionSettings.audio.schedule).map(renderSchedule) }
				</div>
			</div>
		</div>
	);
}

export default memo(Audio);
