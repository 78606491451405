import { ISelectOption } from "@clintonelec/react-storybook";

export const groupSelectOptions: ISelectOption[] = [
	{
		label: "ADMIN",
		value: "ADMIN"
	},
	{
		label: "MANAGER",
		value: "MANAGER"
	},
	{
		label: "USER",
		value: "USER"
	}
];

export enum UserPermissions {
	SEARCH = "Search",
	EVENT_ACTION_CONTROL = "Event Action Control",
	LISTEN_TO_AUDIO = "Listen To Audio",
	MIC = "Mic",
	SYSTEM_REBOOT = "System Reboot",
	FACTORY_DEFAULT = "Factory Default",
	SYSTEM_SETUP = "System Setup"
}
