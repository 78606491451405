import { PayloadAction } from "@reduxjs/toolkit";
import { MotionMode, MotionObject } from "Data/Objects/Camera";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IMotionCameraState } from "Interfaces";

const initialState: IMotionCameraState = {
	bikeConfidenceLevel: 0,
	daySensitivityLevel: 0,
	motionMode: MotionMode.VIDEO,
	nightSensitivityLevel: 0,
	personConfidenceLevel: 0,
	selectedMotionObjects: [ MotionObject.PERSON ],
	vehicleConfidenceLevel: 0,
	zones: [ [], [], [], [] ]
};

// #region reducers

export const setMotionSettingsReducer = (state: IMotionCameraState, action: PayloadAction<IMotionCameraState>) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region Selectors

const getMotionSettings = (state: IMotionCameraState) => {
	return state;
};

// #endregion

const motionSlice = createAppSlice({
	name: "motion",
	initialState,
	reducers: {
		setMotionSettingsAction: setMotionSettingsReducer
	},
	selectors: {
		selectMotionSettings: getMotionSettings
	}
});

export default motionSlice;

export const { setMotionSettingsAction } = motionSlice.actions;
export const { selectMotionSettings } = motionSlice.getSelectors<AppState>(state => state.settings.camera.motion);
