// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interactive-polygon {
  inset: 0;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Global/InteractivePolygon/InteractivePolygon.less"],"names":[],"mappings":"AAEA;EACC,QAAA;EACA,kBAAA;AADD","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.interactive-polygon {\n\tinset: 0;\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
