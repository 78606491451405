// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-audio-modal-content .restriction-message {
  margin-bottom: 10px;
}
.add-audio-modal-content .file-name {
  margin-bottom: 10px;
}
.add-audio-modal-content .file-selector {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.add-audio-modal-content .file-selector .cec-input-wrapper {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/ActionSettings/AddAudioModal/AddAudioModal.less"],"names":[],"mappings":"AAEA;EAEE,mBAAA;AAFF;AAAA;EAME,mBAAA;AAHF;AAHA;EAUE,mBAAA;EACA,aAAA;EACA,mBAAA;AAJF;AARA;EAeG,iBAAA;AAJH","sourcesContent":["@import (reference) \"../../../../../Styles/colors\";\n\n.add-audio-modal-content {\n\t.restriction-message {\n\t\tmargin-bottom: 10px;\n\t}\n\n\t.file-name {\n\t\tmargin-bottom: 10px;\n\t}\n\n\t.file-selector {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tmargin-bottom: 20px;\n\n\t\t.cec-input-wrapper {\n\t\t\tmargin-left: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
