import { dom, library } from "@fortawesome/fontawesome-svg-core";
import lightIcons from "src/FontAwesome/LightIcons";
import regularIcons from "src/FontAwesome/RegularIcons";
import solidIcons from "src/FontAwesome/SolidIcons";

export default function initFontAwesome() {
	library.add(regularIcons);
	library.add(solidIcons);
	library.add(lightIcons);

	dom.watch();
}
