import { IPoint } from "Interfaces";

export const pointInsidePolygon = (point: IPoint, points: IPoint[]) => {
	let inside = false;

	if (!point) {
		return false;
	}

	points.forEach((currentPoint, index) => {
		const { x: currentX, y: currentY } = currentPoint;
		const nextPointIndex = (index + 1) % points.length;
		const { x: nextX, y: nextY } = points[ nextPointIndex ];
		const isBetweenVertices = (currentY > point.y) !== (nextY > point.y);
		const intersectionPoint = (nextX - currentX) * (point.y - currentY) / (nextY - currentY) + currentX;
		const rayIntersectsSegment = isBetweenVertices && point.x < intersectionPoint;

		if (rayIntersectsSegment) {
			inside = !inside;
		}
	});

	return inside;
};

export const pointToSegmentDistance = (point: IPoint, segmentStart: IPoint, segmentEnd: IPoint) => {
	const deltaX = segmentEnd.x - segmentStart.x;
	const deltaY = segmentEnd.y - segmentStart.y;
	const segmentLengthSquared = Math.pow(deltaX, 2) + Math.pow(deltaY, 2);

	if (segmentLengthSquared === 0) {
		return Math.hypot(point.x - segmentStart.x, point.y - segmentStart.y);
	}

	const pointToStartX = (point.x - segmentStart.x) * deltaX;
	const pointToStartY = (point.y - segmentStart.y) * deltaY;
	const projectionFactor = (pointToStartX + pointToStartY) / segmentLengthSquared;
	const clampedProjectionFactor = Math.max(0, Math.min(1, projectionFactor));
	const closestPointX = segmentStart.x + clampedProjectionFactor * deltaX;
	const closestPointY = segmentStart.y + clampedProjectionFactor * deltaY;

	return Math.hypot(point.x - closestPointX, point.y - closestPointY);
};
