import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/System/SecuritySettings/SecuritySettings.less";
import { ISelectOption, Select, SelectComponent, Switch } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { ISetSystemSecurityPayload, ISystemSecuritySettingsState } from "Interfaces";
import {
	selectSystemSecuritySettings, setSystemSecuritySettingsAction
} from "Data/Redux/Slices/Settings/System/Security";
import { produce } from "immer";
import { isEqual, merge } from "lodash";

const durationOptions: ISelectOption[] =
	[ 1, 2, 3, 4, 5, 10, 15, 20, 30, 60, 120 ].map(duration => ({
		label: `${ duration } Min`,
		value: duration
	}));

interface ISecuritySettingsForm extends HTMLFormElement {
	autoLogoutSelect: SelectComponent;
	autoLogoutDurationSelect: SelectComponent;
}

const SecuritySettings = () => {
	const dispatch = useAppDispatch();
	const securitySettings = useAppSelector(selectSystemSecuritySettings);
	const [ securitySettingsDiff, setSecuritySettingsDiff ] = useState<Partial<ISystemSecuritySettingsState>>({});
	const setSystemSecuritySettings = (newSettings: ISetSystemSecurityPayload) =>
		dispatch(setSystemSecuritySettingsAction(newSettings));

	const localSecuritySettings = produce(securitySettings, (draft) => {
		merge(draft, securitySettingsDiff);
	});

	const handleSubmit = (event: FormEvent<ISecuritySettingsForm>) => {
		event.preventDefault();

		setSystemSecuritySettings(localSecuritySettings);
	};

	const handleReset = (event: FormEvent<ISecuritySettingsForm>) => {
		event.preventDefault();

		setSecuritySettingsDiff({});
	};

	const handleAutoLogoutDurationSelect = (value: string) => {
		const newDiffState = produce(localSecuritySettings, draft => {
			draft.autoLogoutDuration = +value;
		});

		setSecuritySettingsDiff(newDiffState);
	};

	const handleAutoLogoutEnabledChange = (checked: boolean) => {
		const newDiffState = produce(securitySettingsDiff, draft => {
			draft.autoLogout = checked;
		});

		setSecuritySettingsDiff(newDiffState);
	};

	return (
		<form
			className="security-settings"
			noValidate
			onReset={ handleReset }
			onSubmit={ handleSubmit }
		>
			<div className="scrollable-container">
				<div className="card">
					<div className="page-title">
						<h4>Security</h4>
					</div>
					<div className="form-row switch">
						<span>Auto Logout</span>
						<Switch
							formName="autoLogoutSelect"
							onChange={ handleAutoLogoutEnabledChange }
							checked={ localSecuritySettings.autoLogout }
						/>
					</div>
					<div className="form-row">
						<span>Auto Logout Duration</span>
						<Select
							allowClear={ false }
							className="security-select"
							disabled={ !localSecuritySettings.autoLogout }
							name="autoLogoutDurationSelect"
							onSelect={ handleAutoLogoutDurationSelect }
							options={ durationOptions }
							value={ localSecuritySettings.autoLogoutDuration }
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localSecuritySettings, securitySettings) } />
		</form>
	);
};

export default memo(SecuritySettings);
