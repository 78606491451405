import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/AnalyticsEventSettings/AnalyticsEventSettings.less";
import { Checkbox, IColumn, ISelectOption, Select, Table } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectAnalyticsEvent, setAnalyticsEventAction } from "Data/Redux/Slices/Settings/Event/Analytics";
import { produce } from "immer";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";

const analyticsEventColumns: IColumn[] = [
	{
		dataIndex: "interval",
		title: "Ignoring Interval",
		key: "interval",
		sortable: false
	},
	{
		dataIndex: "alarmOut",
		title: "Alarm Out",
		key: "alarmOut",
		sortable: false
	},
	{
		dataIndex: "sendImage",
		title: "Send Image",
		key: "sendImage",
		sortable: false
	},
	{
		dataIndex: "whiteLight",
		title: "White Light",
		key: "whiteLight",
		sortable: false
	},
	{
		dataIndex: "redBlueLight",
		title: "Red & Blue Light",
		key: "redBlueLight",
		sortable: false
	},
	{
		dataIndex: "audioAlarm",
		title: "Audio Alarm",
		key: "audioAlarm",
		sortable: false
	},
	{
		dataIndex: "recording",
		title: "Recording",
		key: "recording",
		sortable: false
	},
	{
		dataIndex: "email",
		title: "Email",
		key: "email",
		sortable: false
	}
];

const intervalSelectOptions: ISelectOption[] = [ 0, 5, 10, 15, 20, 25, 30 ].map(value => ({
	label: value === 0 ? "Off" : `${ value }`,
	value
}));

interface IAnalyticsEventForm extends HTMLFormElement {
	eventName: HTMLInputElement;
	alarmOut: HTMLInputElement;
	sendImage: HTMLInputElement;
	whiteLight: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
}

function AnalyticsEventSettings() {
	const dispatch = useAppDispatch();
	const analyticsEventSettings = useAppSelector(selectAnalyticsEvent);
	const [ localAnalyticsEventSettings, setLocalAnalyticsEventSettings ] = useState(analyticsEventSettings);
	const setAnalyticsEvent = () => dispatch(setAnalyticsEventAction(localAnalyticsEventSettings));

	const handleFormChange = (event: FormEvent<IAnalyticsEventForm>) => {
		const {
			alarmOut: { checked: newAlarmOut },
			sendImage: { checked: newSendImage },
			whiteLight: { checked: newWhiteLight },
			redBlueLight: { checked: newRedBlueLight },
			audioAlarm: { checked: newAudioAlarm },
			recording: { checked: newRecording },
			email: { checked: newEmail }
		} = event.currentTarget;

		const newLocalSettings = produce(localAnalyticsEventSettings, draft => {
			draft.alarmOut = newAlarmOut;
			draft.sendImage = newSendImage;
			draft.whiteLight = newWhiteLight;
			draft.redBlueLight = newRedBlueLight;
			draft.audioAlarm = newAudioAlarm;
			draft.recording = newRecording;
			draft.email = newEmail;
		});

		setLocalAnalyticsEventSettings(newLocalSettings);
	};

	const handleFormSubmit = (event: FormEvent<IAnalyticsEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setAnalyticsEvent();
		}
	};

	const handleFormReset = (event: FormEvent<IAnalyticsEventForm>) => {
		event.preventDefault();

		setLocalAnalyticsEventSettings(analyticsEventSettings);
	};

	const handleIntervalSelect = (interval: string) => {
		const newLocalState = produce(localAnalyticsEventSettings, draft => {
			draft.interval = +interval;
		});

		setLocalAnalyticsEventSettings(newLocalState);
	};

	const tableData = [
		{
			key: "analyticsEventInterval",
			interval: (
				<Select
					allowClear={ false }
					className="interval-select"
					onSelect={ handleIntervalSelect }
					options={ intervalSelectOptions }
					value={ localAnalyticsEventSettings.interval }
				/>
			),
			alarmOut: <Checkbox name="alarmOut" checked={ localAnalyticsEventSettings.alarmOut } />,
			sendImage: <Checkbox name="sendImage" checked={ localAnalyticsEventSettings.sendImage } />,
			whiteLight: <Checkbox name="whiteLight" checked={ localAnalyticsEventSettings.whiteLight } />,
			redBlueLight: <Checkbox name="redBlueLight" checked={ localAnalyticsEventSettings.redBlueLight } />,
			audioAlarm: <Checkbox name="audioAlarm" checked={ localAnalyticsEventSettings.audioAlarm } />,
			recording: <Checkbox name="recording" checked={ localAnalyticsEventSettings.recording } />,
			email: <Checkbox name="email" checked={ localAnalyticsEventSettings.email } />
		}
	];

	return (
		<form
			className="analytics-event-settings-form"
			noValidate
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="analytics-event-settings-container">
					<div className="analytics-event-settings">
						<div className="card">
							<div className="page-title">
								<h4>AI Analytics Event</h4>
							</div>
							<div className="info-card">
								Notification - Actions and notifications require detailed settings on each settings
								page to work.
							</div>
							<Table
								columns={ analyticsEventColumns }
								data={ tableData }
							/>
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localAnalyticsEventSettings, analyticsEventSettings) } />
		</form>
	);
}

export default memo(AnalyticsEventSettings);
