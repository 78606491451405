import { AspectMaintainer, Button, ButtonTypes, Input, RangeSlider, Select } from "@clintonelec/react-storybook";
import { irisOptions, onOffOptions } from "Data/Objects/Camera";
import { produce } from "immer";
import { IImageSettingsState, IPoint } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";
import InteractivePolygon from "Components/Global/InteractivePolygon";

interface IZoomFocusProps {
	localSettings: IImageSettingsState;
	setLocalSettings: Dispatch<SetStateAction<IImageSettingsState>>;
}

function ZoomFocus(props: IZoomFocusProps) {
	const { localSettings, setLocalSettings } = props;
	const { zoomFocusArea, zoom, focus, iris, dayNightFocusShift } = localSettings.zoomFocus;

	const selectFocusedArea = (area: IPoint[]) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.zoomFocusArea = area;
		}));
	};

	const setDefaultArea = () => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.zoomFocusArea = [
				{ x: 0, y: 0 },
				{ x: 150, y: 0 },
				{ x: 150, y: 150 },
				{ x: 0, y: 150 }
			];
		}));
	};

	const changeZoom = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.zoom = value;
		}));
	};

	const updateZoom = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.zoom = +value;
		}));
	};

	const changeFocus = (value: number) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.focus = value;
		}));
	};

	const updateFocus = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.focus = +value;
		}));
	};

	const selectIris = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.iris = value;
		}));
	};

	const selectDayNightFocusShift = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.zoomFocus.dayNightFocusShift = value === "ON";
		}));
	};

	return (
		<Fragment>
			<div className="content-left">
				<AspectMaintainer>
					<div className="camera-snapshot">
						<InteractivePolygon
							points={ zoomFocusArea }
							onUpdate={ selectFocusedArea }
							isBox={ true }
						/>
					</div>
				</AspectMaintainer>
				<div className="form-row">
					<div className="span-button-wrapper">
						<span>Draw a rectangle to define the focus area.</span>
						<Button
							htmlType="button"
							onClick={ setDefaultArea }
							type={ ButtonTypes.TERTIARY }
						>
							Default Area
						</Button>
					</div>
				</div>
			</div>
			<div className="content-right">
				<div className="form-row">
					<span>Zoom</span>
					<div className="image-slider-input-wrapper">
						<RangeSlider
							max={ 1335 }
							min={ 0 }
							onAfterChange={ changeZoom }
							value={ zoom }
						/>
						<Input
							hideCharMessage={ true }
							max={ 1335 }
							min={ 0 }
							onUpdate={ updateZoom }
							type="number"
							value={ zoom }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Focus</span>
					<div className="image-slider-input-wrapper">
						<RangeSlider
							max={ 3018 }
							min={ 0 }
							onAfterChange={ changeFocus }
							value={ focus }
						/>
						<Input
							hideCharMessage={ true }
							max={ 3018 }
							min={ 0 }
							onUpdate={ updateFocus }
							type="number"
							value={ focus }
						/>
					</div>
				</div>
				<div className="form-row">
					<span>Iris</span>
					<Select
						allowClear={ false }
						className="image-select"
						onSelect={ selectIris }
						options={ irisOptions }
						value={ iris }
					/>
				</div>
				<div className="form-row">
					<span>Day/Night Focus Shift</span>
					<Select
						allowClear={ false }
						className="image-select"
						onSelect={ selectDayNightFocusShift }
						options={ onOffOptions }
						value={ dayNightFocusShift ? "ON" : "OFF" }
					/>
				</div>
				<div className="form-row">&nbsp;</div>
				<div className="form-row">
					<span>Refocus Lens</span>
					<Button className="image-button" htmlType="button" type={ ButtonTypes.TERTIARY }>Refocus</Button>
				</div>
				<div className="form-row">
					<span>Lens Reset</span>
					<Button className="image-button" htmlType="button" type={ ButtonTypes.TERTIARY }>Home</Button>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(ZoomFocus);
