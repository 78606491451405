import { Input, Select } from "@clintonelec/react-storybook";
import { snmpAuthProtocolOptions, snmpPrivacyProtocolOptions } from "Data/Objects/Network";
import { produce } from "immer";
import { ISnmpSettingsState, SnmpAuthenticationProtocol, SnmpPrivacyProtocol } from "Interfaces";
import { Fragment, memo } from "react";

const authenticationKeyRegex = new RegExp(/^.{8,128}$/);

interface ISnmpV3Props {
	formSubmitted: boolean;
	localSnmpSettings: ISnmpSettingsState;
	setSnmpSettings: (snmpSettings: ISnmpSettingsState) => void;
}

function SnmpV3Options(props: ISnmpV3Props) {
	const { formSubmitted, localSnmpSettings, setSnmpSettings } = props;

	const handleAuthProtocolSelect = (newProtocol: SnmpAuthenticationProtocol) => {
		const newLocalState = produce(localSnmpSettings, draft => {
			draft.v3.authProtocol = newProtocol;
		});

		setSnmpSettings(newLocalState);
	};

	const handlePrivacyProtocolSelect = (newProtocol: SnmpPrivacyProtocol) => {
		const newLocalState = produce(localSnmpSettings, draft => {
			draft.v3.privacyProtocol = newProtocol;
		});

		setSnmpSettings(newLocalState);
	};

	return (
		<Fragment>
			<div className="form-row space">
				<span>User Name</span>
				<Input
					name="username"
					value={ localSnmpSettings.v3.username }
					wrapClassName="snmp-input"
				/>
			</div>
			<div className="form-row">
				<span>Authentication Protocol</span>
				<Select
					allowClear={ false }
					className="snmp-select"
					onSelect={ handleAuthProtocolSelect }
					options={ snmpAuthProtocolOptions }
					value={ localSnmpSettings.v3.authProtocol }
				/>
			</div>
			<div className="form-row">
				<span>Authentication Password</span>
				<Input
					disabled={ localSnmpSettings.v3.authProtocol === SnmpAuthenticationProtocol.NONE }
					name="authPassword"
					noValidate={ !formSubmitted }
					required={ localSnmpSettings.v3.authProtocol !== SnmpAuthenticationProtocol.NONE }
					validator={ authenticationKeyRegex }
					validityMessage="The password must be between 8 and 128 characters."
					value={ localSnmpSettings.v3.authPassword }
					wrapClassName="snmp-input"
				/>
			</div>
			<div className="form-row">
				<span>Privacy Protocol</span>
				<Select
					allowClear={ false }
					className="snmp-select"
					onSelect={ handlePrivacyProtocolSelect }
					options={ snmpPrivacyProtocolOptions }
					value={ localSnmpSettings.v3.privacyProtocol }
				/>
			</div>
			<div className="form-row">
				<span>Privacy Password</span>
				<Input
					disabled={ localSnmpSettings.v3.privacyProtocol === SnmpPrivacyProtocol.NONE }
					name="privacyPassword"
					validator={ authenticationKeyRegex }
					validityMessage="The password must be between 8 and 128 characters."
					required={ localSnmpSettings.v3.privacyProtocol !== SnmpPrivacyProtocol.NONE }
					value={ localSnmpSettings.v3.privacyPassword }
					wrapClassName="snmp-input"
				/>
			</div>
		</Fragment>
	);
}

export default memo(SnmpV3Options);
