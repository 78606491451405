import { AspectMaintainer, Input, ISelectOption, Select } from "@clintonelec/react-storybook";
import {
	agcGainOptions, AntiFlickerMode, antiFlickerModeOptions, ExposureMode, exposureModeOptions, MaxAgc, maxAgcOptions,
	PIrisControl, ShutterSpeed, ShutterSpeed50Hz, shutterSpeedAntiFlickerMode50HzOptions,
	shutterSpeedAntiFlickerMode60HzOptions, shutterSpeedOptions, SlowShutter, slowShutterOptions
} from "Data/Objects/Camera";
import { produce } from "immer";
import { IImageSettingsState } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";

interface IExposureProps {
	localSettings: IImageSettingsState;
	renderPopover: (message: string) => JSX.Element;
	setLocalSettings: Dispatch<SetStateAction<IImageSettingsState>>;
}

function Exposure(props: IExposureProps) {
	const { localSettings, setLocalSettings, renderPopover } = props;
	const {
		exposureMode, agcGain, eShutterSpeed, maxAgc, baseShutterSpeed, maxShutterSpeed, slowShutter, antiFlickerMode,
		pIrisControl
	} = localSettings.exposure;

	const isManualExposure = exposureMode === ExposureMode.MANUAL;

	const baseShutterSpeedOptions = (): ISelectOption[] => {
		const maxShutterSpeedIndex = Object.values(ShutterSpeed).findIndex(value => value === maxShutterSpeed);

		switch (exposureMode) {
			case ExposureMode.AUTO: {
				switch (antiFlickerMode) {
					case AntiFlickerMode.A_50: {
						return shutterSpeedAntiFlickerMode50HzOptions;
					}

					case AntiFlickerMode.A_60: {
						return shutterSpeedAntiFlickerMode60HzOptions;
					}

					default: {
						return shutterSpeedOptions.filter((option, index) => index >= maxShutterSpeedIndex);
					}
				}
			}

			case ExposureMode.AUTO_MOTION_PRIORITY: {
				switch (antiFlickerMode) {
					case AntiFlickerMode.A_50: {
						return [
							{
								label: ShutterSpeed50Hz.S_100,
								value: ShutterSpeed50Hz.S_100
							}
						];
					}

					case AntiFlickerMode.A_60: {
						return [
							{
								label: ShutterSpeed.S_120,
								value: ShutterSpeed.S_120
							}
						];
					}

					default: {
						return shutterSpeedOptions.filter((option, index) => index >= 2);
					}
				}
			}

			default:
				break;
		}
	};

	const maxShutterSpeedOptions = (): ISelectOption[] => {
		switch (exposureMode) {
			case ExposureMode.AUTO: {
				switch (antiFlickerMode) {
					case AntiFlickerMode.A_50: {
						return shutterSpeedAntiFlickerMode50HzOptions;
					}

					case AntiFlickerMode.A_60: {
						return shutterSpeedAntiFlickerMode60HzOptions;
					}

					default: {
						return shutterSpeedOptions;
					}
				}
			}

			case ExposureMode.AUTO_MOTION_PRIORITY: {
				switch (antiFlickerMode) {
					case AntiFlickerMode.A_50: {
						return shutterSpeedAntiFlickerMode50HzOptions;
					}

					default: {
						return shutterSpeedOptions.filter((option, index) => {
							return index <= 2;
						});
					}
				}
			}

			default: {

				break;
			}
		}
	};

	const pIrisControlOptions = () => {
		const options: ISelectOption[] = [
			{
				label: "Auto",
				value: PIrisControl.AUTO
			},
			{
				label: "Manual",
				value: PIrisControl.MANUAL
			},
			{
				label: "Best Quality",
				value: PIrisControl.BEST_QUALITY
			},
			{
				label: "Depth Of Field",
				value: PIrisControl.DEPTH_OF_FIELD
			}
		];

		if (exposureMode === ExposureMode.AUTO_MOTION_PRIORITY) {
			return options.slice(0, 2);
		}

		return options;
	};

	const selectExposureMode = (value: ExposureMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.exposureMode = value;

			switch (value) {
				case ExposureMode.AUTO: {
					switch (antiFlickerMode) {
						case AntiFlickerMode.A_50: {
							draft.exposure.baseShutterSpeed = ShutterSpeed50Hz.S_25;
							draft.exposure.maxShutterSpeed = ShutterSpeed50Hz.S_25;

							break;
						}

						case AntiFlickerMode.A_60: {
							draft.exposure.baseShutterSpeed = ShutterSpeed.S_30;
							draft.exposure.maxShutterSpeed = ShutterSpeed.S_30;

							break;
						}

						default: {

							break;
						}
					}

					break;
				}

				case ExposureMode.AUTO_MOTION_PRIORITY: {
					switch (antiFlickerMode) {
						case AntiFlickerMode.A_50: {
							draft.exposure.baseShutterSpeed = ShutterSpeed50Hz.S_100;
							draft.exposure.maxShutterSpeed = ShutterSpeed50Hz.S_25;

							break;
						}

						default: {
							draft.exposure.baseShutterSpeed = ShutterSpeed.S_120;
							draft.exposure.maxShutterSpeed = ShutterSpeed.S_30;

							break;
						}
					}

					draft.exposure.pIrisControl = PIrisControl.AUTO;

					break;
				}

				default: {

					break;
				}
			}
		}));
	};

	const selectAgcGain = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.agcGain = +value;
		}));
	};

	const updateEShutterSpeed = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.eShutterSpeed = +value;
		}));
	};

	const selectMaxAgc = (value: MaxAgc) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.maxAgc = value;
		}));
	};

	const selectBaseShutterSpeed = (value: ShutterSpeed) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.baseShutterSpeed = value;
		}));
	};

	const selectMaxShutterSpeed = (value: ShutterSpeed) => {
		const currentBaseShutterSpeedIndex = Object.values(ShutterSpeed).findIndex(speed => speed === baseShutterSpeed);
		const selectedMaxShutterSpeedIndex = Object.values(ShutterSpeed).findIndex(speed => speed === value);

		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.maxShutterSpeed = value;

			if (currentBaseShutterSpeedIndex < selectedMaxShutterSpeedIndex) {
				draft.exposure.baseShutterSpeed = value;
			}
		}));
	};

	const selectSlowShutter = (value: SlowShutter) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.slowShutter = value;
		}));
	};

	const selectAntiFlickerMode = (value: AntiFlickerMode) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.antiFlickerMode = value;

			switch (value) {
				case AntiFlickerMode.A_50: {
					switch (exposureMode) {
						case ExposureMode.AUTO: {
							draft.exposure.baseShutterSpeed = ShutterSpeed50Hz.S_25;
							draft.exposure.maxShutterSpeed = ShutterSpeed50Hz.S_25;

							break;
						}

						default: {
							draft.exposure.baseShutterSpeed = ShutterSpeed50Hz.S_100;
							draft.exposure.maxShutterSpeed = ShutterSpeed50Hz.S_25;

							break;
						}
					}

					break;
				}

				default: {
					switch (exposureMode) {
						case ExposureMode.AUTO: {
							draft.exposure.baseShutterSpeed = ShutterSpeed.S_30;
							draft.exposure.maxShutterSpeed = ShutterSpeed.S_30;

							break;
						}

						default: {
							draft.exposure.baseShutterSpeed = ShutterSpeed.S_120;
							draft.exposure.maxShutterSpeed = ShutterSpeed.S_30;

							break;
						}
					}

					break;
				}
			}
		}));
	};

	const selectPIrisControl = (value: PIrisControl) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.exposure.pIrisControl = value;
		}));
	};

	const renderInfoIcon = () => {
		if (localSettings.enhancement.wideDynamicRange && !isManualExposure) {
			return renderPopover("To use this setting, go to Enhancement tab and switch 'Wide Dynamic Range' to Off");
		}
	};

	return (
		<Fragment>
			<div className="content-left">
				<AspectMaintainer>
					<div className="camera-snapshot"></div>
				</AspectMaintainer>
			</div>
			<div className="content-right">
				<div className="form-row">
					<span>Exposure Mode</span>
					<Select
						allowClear={ false }
						className="image-select"
						onSelect={ selectExposureMode }
						options={ exposureModeOptions }
						value={ exposureMode }
					/>
				</div>
				<div className="form-row">
					<span>AGC Gain</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ !isManualExposure }
						onSelect={ selectAgcGain }
						options={ agcGainOptions }
						value={ agcGain }
					/>
				</div>
				<div className="form-row">
					<span>E-Shutter Speed</span>
					<div className="image-input-span-wrapper">
						<Input
							disabled={ !isManualExposure }
							hideCharMessage={ true }
							max={ 2000 }
							min={ 4 }
							onUpdate={ updateEShutterSpeed }
							type="number"
							value={ eShutterSpeed }
						/>
						<span>(4 ~ 2000)</span>
					</div>
				</div>
				<div className="form-row">
					<span>Max AGC</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ isManualExposure }
						onSelect={ selectMaxAgc }
						options={ maxAgcOptions }
						value={ maxAgc }
					/>
				</div>
				<div className="form-row">
					<span>Base Shutter Speed</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={
							isManualExposure
							|| (exposureMode === ExposureMode.AUTO && pIrisControl !== PIrisControl.AUTO)
						}
						onSelect={ selectBaseShutterSpeed }
						options={ baseShutterSpeedOptions() }
						value={ baseShutterSpeed }
					/>
				</div>
				<div className="form-row">
					<span>Max Shutter Speed</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={
							isManualExposure
							|| slowShutter !== SlowShutter.OFF
							|| pIrisControl === PIrisControl.BEST_QUALITY
							|| pIrisControl === PIrisControl.DEPTH_OF_FIELD
						}
						onSelect={ selectMaxShutterSpeed }
						options={ maxShutterSpeedOptions() }
						value={ maxShutterSpeed }
					/>
				</div>
				<div className="form-row">
					<span>Slow Shutter</span>
					{ renderInfoIcon() }
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ isManualExposure || localSettings.enhancement.wideDynamicRange }
						onSelect={ selectSlowShutter }
						options={ slowShutterOptions }
						value={ slowShutter }
					/>
				</div>
				<div className="form-row">
					<span>Anti-Flicker Mode</span>
					{ renderInfoIcon() }
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ isManualExposure || localSettings.enhancement.wideDynamicRange }
						onSelect={ selectAntiFlickerMode }
						options={ antiFlickerModeOptions }
						value={ antiFlickerMode }
					/>
				</div>
				<div className="form-row">
					<span>P-Iris Control</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ isManualExposure }
						onSelect={ selectPIrisControl }
						options={ pIrisControlOptions() }
						value={ pIrisControl }
					/>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(Exposure);
