import {
	BitrateControl, Codec, ImageQuality, Profile, Resolution, VideoFormat, AntiFlickerMode, BacklightCompensation,
	DayNightMode, DefogControl, DigitalNoiseReduction, DwellTime, EnhancementActivation, ExposureMode,
	HighlightCompensation, IlluminationMode, IrLed, ManualWbMode, MaxAgc, MirrorImage, PIrisControl, ShutterSpeed,
	SlowShutter, WhiteBalanceMode, WhiteLedControl
} from "Data/Objects/Camera";
import { ICodecSettingsState, IImageSettingsState } from "Interfaces";

export const DUMMY_CODEC_SETTINGS: ICodecSettingsState = {
	streams: [
		{
			enableStream: true,
			codec: Codec.H264,
			profile: Profile.BASELINE,
			resolution: Resolution.R1920X1080,
			frameRate: 30,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			codec: Codec.H264,
			profile: Profile.MAIN,
			resolution: Resolution.R1280X1024,
			frameRate: 2,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			codec: Codec.H264,
			profile: Profile.MAIN,
			resolution: Resolution.R1280X1024,
			frameRate: 2,
			gopSize: 1,
			bitrateControl: BitrateControl.CBR,
			bitrate: 512,
			imageQuality: ImageQuality.LOW,
			mjpgQuality: 0,
			dynamicOptions: false,
			dynamicFps: 1,
			dynamicBitrate: 512,
			dynamicGopSize: 1
		},
		{
			enableStream: false,
			useStreamSettings: "mainStream",
			frameRateLabel: 0.5,
			frameRate: 15
		}
	],
	videoFormat: VideoFormat.NTSC
};

export const DUMMY_IMAGE_SETTINGS: IImageSettingsState = {
	appearance: {
		mirrorImage: MirrorImage.HORIZONTAL_VERTICAL,
		brightness: 15,
		contrast: 15,
		color: 15,
		sharpness: 8,
		whiteBalanceMode: WhiteBalanceMode.AUTO,
		manualWbMode: ManualWbMode.INDOOR
	},
	exposure: {
		exposureMode: ExposureMode.AUTO,
		agcGain: 0,
		eShutterSpeed: 30,
		maxAgc: MaxAgc.HIGH,
		baseShutterSpeed: ShutterSpeed.S_30,
		maxShutterSpeed: ShutterSpeed.S_30,
		slowShutter: SlowShutter.OFF,
		antiFlickerMode: AntiFlickerMode.OFF,
		pIrisControl: PIrisControl.AUTO
	},
	enhancement: {
		wideDynamicRange: true,
		backlightCompensation: BacklightCompensation.OFF,
		highlightCompensation: HighlightCompensation.OFF,
		digitalNoiseReduction: DigitalNoiseReduction.OFF,
		defogControl: DefogControl.OFF,
		enhancementActivation: EnhancementActivation.DAY
	},
	dayNight: {
		dayNightMode: DayNightMode.AUTO,
		dwellTime: DwellTime.D0,
		dayTimeStart: null,
		dayTimeEnd: null,
		dayToNightSensitivity: 0,
		nightToDaySensitivity: 3,
		illuminationMode: IlluminationMode.IR,
		irLed: IrLed.AUTO,
		adaptiveIr: false,
		irLedIllumination: 255,
		antiOverexposure: true,
		whiteLedControl: WhiteLedControl.AUTO,
		whiteLedIllumination: 255
	},
	zoomFocus: {
		zoomFocusArea: [
			{ x: 0, y: 0 },
			{ x: 150, y: 0 },
			{ x: 150, y: 150 },
			{ x: 0, y: 150 }
		],
		zoom: 40,
		focus: 658,
		iris: "Auto Mode",
		dayNightFocusShift: false
	}
};
