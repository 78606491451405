import { Input, RangeSlider } from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IMotionCameraState } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";

interface IVideoMotionControlsProps {
	localMotionSettings: IMotionCameraState;
	setLocalMotionSettings: Dispatch<SetStateAction<IMotionCameraState>>;
}

function VideoMotionControls(props: IVideoMotionControlsProps) {
	const {
		localMotionSettings, localMotionSettings: { daySensitivityLevel, nightSensitivityLevel }, setLocalMotionSettings
	} = props;

	const handleUpdateDaySensitivity = (value: string) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.daySensitivityLevel = +value;
		}));
	};

	const handleDaySensitivityOnChange = (value: number) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.daySensitivityLevel = value;
		}));
	};

	const handleUpdateNightSensitivity = (value: string) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.nightSensitivityLevel = +value;
		}));
	};

	const handleNightSensitivityOnChange = (value: number) => {
		setLocalMotionSettings(produce(localMotionSettings, draft => {
			draft.nightSensitivityLevel = value;
		}));
	};

	return (
		<Fragment>
			<div className="motion-setting-row">
				<span>Day Sensitivity</span>
				<div className="video-motion-object">
					<RangeSlider
						onAfterChange={ handleDaySensitivityOnChange }
						value={ daySensitivityLevel }
					/>
					<Input
						hideCharMessage
						onUpdate={ handleUpdateDaySensitivity }
						min={ 0 }
						max={ 100 }
						type="number"
						value={ daySensitivityLevel }
					/>
				</div>
			</div>
			<div className="motion-setting-row">
				<span>Night Sensitivity</span>
				<div className="video-motion-object">
					<RangeSlider
						onAfterChange={ handleNightSensitivityOnChange }
						value={ nightSensitivityLevel }
					/>
					<Input
						hideCharMessage
						onUpdate={ handleUpdateNightSensitivity }
						min={ 0 }
						max={ 100 }
						type="number"
						value={ nightSensitivityLevel }
					/>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(VideoMotionControls);
