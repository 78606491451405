// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alarm-event-settings-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
.alarm-event-settings-form .scrollable-container {
  display: flex;
  flex: 1;
  overflow: auto;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container {
  margin: 0 auto;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: -moz-max-content;
  width: max-content;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card .header {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card .header h4 {
  margin-block-end: 5px;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card .alarm-settings-banner {
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 8px;
  color: #468847;
  margin-bottom: 10px;
  padding: 8px 16px;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card .alarm-settings-banner span:first-child {
  font-weight: bold;
}
.alarm-event-settings-form .scrollable-container .alarm-settings-container .alarm-settings .card .cec-checkbox {
  left: 50%;
  transform: translate(-50%, -20%);
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/Event/AlarmEventSettings/AlarmEventSettings.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;EACA,cAAA;AADD;AAHA;EAOE,aAAA;EACA,OAAA;EACA,cAAA;AADF;AARA;EAYG,cAAA;AADH;AAXA;EAeI,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAlBA;EAsBK,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,uBAAA;EAAA,kBAAA;AADL;AAzBA;EA6BM,gCAAA;EACA,mBAAA;AADN;AA7BA;EAiCO,qBAAA;AADP;AAhCA;EAsCM,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,iBAAA;AAHN;AAxCA;EA8CO,iBAAA;AAHP;AA3CA;EAmDM,SAAA;EACA,gCAAA;AALN","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.alarm-event-settings-form {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\toverflow: auto;\n\n\t.scrollable-container {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\toverflow: auto;\n\n\t\t.alarm-settings-container {\n\t\t\tmargin: 0 auto;\n\n\t\t\t.alarm-settings {\n\t\t\t\talign-items: center;\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex: 1;\n\t\t\t\tflex-direction: column;\n\t\t\t\tpadding: 30px;\n\n\t\t\t\t.card {\n\t\t\t\t\tbackground-color: white;\n\t\t\t\t\tborder-radius: 8px;\n\t\t\t\t\theight: fit-content;\n\t\t\t\t\tpadding: 0 20px 20px;\n\t\t\t\t\twidth: max-content;\n\n\t\t\t\t\t.header {\n\t\t\t\t\t\tborder-bottom: 1px solid @light-gray-1;\n\t\t\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\t\t\th4 {\n\t\t\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\n\t\t\t\t\t.alarm-settings-banner {\n\t\t\t\t\t\tbackground-color: #dff0d8;\n\t\t\t\t\t\tborder: 1px solid #d6e9c6;\n\t\t\t\t\t\tborder-radius: 8px;\n\t\t\t\t\t\tcolor: #468847;\n\t\t\t\t\t\tmargin-bottom: 10px;\n\t\t\t\t\t\tpadding: 8px 16px;\n\t\t\t\t\t\t\n\t\t\t\t\t\tspan:first-child {\n\t\t\t\t\t\t\tfont-weight: bold;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\n\t\t\t\t\t.cec-checkbox {\n\t\t\t\t\t\tleft: 50%;\n\t\t\t\t\t\ttransform: translate(-50%, -20%);\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
