import { ISelectOption } from "@clintonelec/react-storybook";
import { Colors, DeviceNamePosition } from "Data/Objects/Camera";

export const onOffOptions: ISelectOption[] = [
	{
		label: "Off",
		value: "OFF"
	},
	{
		label: "On",
		value: "ON"
	}
];

export const deviceNameOptions: ISelectOption[] = [
	{
		label: "Off",
		value: DeviceNamePosition.OFF
	},
	{
		label: "Upper-left",
		value: DeviceNamePosition.UPPER_LEFT
	},
	{
		label: "Upper-right",
		value: DeviceNamePosition.UPPER_RIGHT
	},
	{
		label: "Lower-left",
		value: DeviceNamePosition.LOWER_LEFT
	},
	{
		label: "Lower-right",
		value: DeviceNamePosition.LOWER_RIGHT
	}
];

export const colorOptions: ISelectOption[] = [
	{
		label: "Red",
		value: Colors.RED
	},
	{
		label: "Orange",
		value: Colors.ORANGE
	},
	{
		label: "Yellow",
		value: Colors.YELLOW
	},
	{
		label: "Green",
		value: Colors.GREEN
	},
	{
		label: "Cyan",
		value: Colors.CYAN
	},
	{
		label: "Blue",
		value: Colors.BLUE
	},
	{
		label: "Magenta",
		value: Colors.MAGENTA
	},
	{
		label: "Black",
		value: Colors.BLACK
	},
	{
		label: "White",
		value: Colors.WHITE
	}
];
