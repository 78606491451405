import { faCameraCctv } from "@fortawesome/pro-solid-svg-icons/faCameraCctv";
import { faVolume } from "@fortawesome/pro-solid-svg-icons/faVolume";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons/faGlobe";
import { faGears } from "@fortawesome/pro-solid-svg-icons/faGears";
import { faHardDrive } from "@fortawesome/pro-solid-svg-icons/faHardDrive";
import { faSirenOn } from "@fortawesome/pro-solid-svg-icons/faSirenOn";
import { faFilm } from "@fortawesome/pro-solid-svg-icons/faFilm";
import { faGear } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";

export default [
	faCameraCctv, faVolume, faUser, faGlobe, faGears, faHardDrive, faSirenOn, faFilm, faGear, faAngleDown, faAngleRight,
	faEyeSlash, faEye, faPlus, faCaretUp, faCaretDown, faPencil, faTrash, faFilter
];