import { ISelectOption } from "@clintonelec/react-storybook";

export const emailRegexObj = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
export const scheduleNote = "Go To Schedule Preset to create a new preset.";

export const emailFrequencyOptions: ISelectOption[] =
	[ 1, 5, 10, 15, 30, 60 ].map(value => {
		return {
			label: `${ value } Sec`,
			value
		};
	});

export enum Operation {
	NORMALLY_OPEN = "N/O",
	NORMALLY_CLOSED = "N/C"
}

export enum AlarmOutOperation {
	ALARM_OUT = "ALARM_OUT",
	ON = "ON",
	OFF = "OFF"
}

export enum FlickerFrequency {
	ALWAYS_ON = "Always On",
	LOW = "Low Frequency",
	MEDIUM = "Medium Frequency",
	HIGH = "High Frequency"
}

export const defaultAudioFiles = [
	"Attention_Surveillance.mp3",
	"chimes.mp3",
	"Ding_Dong.mp3",
	"Police_Long.mp3",
	"Police_Short.mp3",
	"Siren_Long.wav",
	"Siren_Short.wav",
	"Warning_Trespass.mp3"
];
