import { memo, useEffect, useMemo, useRef, useState } from "react";
import { produce } from "immer";
import { IPoint } from "Interfaces";
import { pointInsidePolygon, pointToSegmentDistance } from "Data/Utils/Point";
import "Components/Global/InteractivePolygon/InteractivePolygon.less";
import { useResizeCanvas } from "Data/Utils/Effects";

const pointColor = "rgb(55, 161, 234)";
const hoveredPointColor = "#87c7f2";
const areaColor = "rgba(55, 161, 234, 0.2)";
const hoveredAreaColor = "rgba(55, 161, 234, 0.3)";
const pointRadius = 8;

interface IInteractivePolygonProps {
	points: IPoint[];
	onUpdate: (newPoints: IPoint[]) => void;
	isBox?: boolean;
}

const InteractivePolygon = (props: IInteractivePolygonProps) => {
	const { points: statePoints, onUpdate: setPoints, isBox } = props;
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [ isDraggingPolygon, setIsDraggingPolygon ] = useState(false);
	const [ currentMousePos, setCurrentMousePos ] = useState<IPoint>(null);
	const [ hoveredPointIndex, setHoveredPointIndex ] = useState<number>(null);
	const [ draggedPointIndex, setDraggedPointIndex ] = useState<number>(null);

	useResizeCanvas(canvasRef);

	const points = useMemo(() => {
		if (statePoints.length > 0) {
			return statePoints;
		}

		const newPoints: IPoint[] = [
			{ x: 0, y: 0 },
			{ x: 150, y: 0 },
			{ x: 150, y: 150 },
			{ x: 0, y: 150 }
		];

		return newPoints;
	}, [ statePoints ]);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");

		// Prevents having to put user-select: none; on literally everything.
		canvas.onselectstart = () => false;

		context.strokeStyle = pointColor;
		context.lineWidth = 2;
		context.clearRect(0, 0, canvas.width, canvas.height);

		if (points.length > 1) {
			const mouseWithin = pointInsidePolygon(currentMousePos, points) && hoveredPointIndex === null;

			context.beginPath();
			context.moveTo(points[0].x, points[0].y);
			points.forEach(point => context.lineTo(point.x, point.y));
			context.closePath();
			context.stroke();
			context.fillStyle = !mouseWithin ? areaColor : hoveredAreaColor;
			context.fill();
		}

		points.forEach((point, index) => {
			context.beginPath();
			context.fillStyle = index === hoveredPointIndex ? hoveredPointColor : pointColor;
			context.arc(point.x, point.y, pointRadius, 0 ,2 * Math.PI);
			context.fill();
		});
	}, [ points, hoveredPointIndex, currentMousePos ]);

	const handleDoubleClick = (event) => {
		if (isBox) {
			return;
		}

		const rect = canvasRef.current.getBoundingClientRect();
		const newPoint: IPoint = {
			x: event.clientX - rect.left,
			y: event.clientY - rect.top
		};

		if (points.length === 10) {
			return;
		}

		if (points.length < 2) {
			setPoints([ ...points, newPoint ]);

			return;
		}

		const newPoints = produce(points, draft => {
			let closestSegmentIndex = -1;
			let minDistance = Infinity;

			draft.forEach((segmentStart, index) => {
				const segmentEndIndex = (index + 1) % points.length;
				const segmentEnd = draft[ segmentEndIndex ];
				const distance = pointToSegmentDistance(newPoint, segmentStart, segmentEnd);

				if (distance < minDistance) {
					minDistance = distance;
					closestSegmentIndex = index;
				}
			});

			draft.splice(closestSegmentIndex + 1, 0, newPoint);
		});

		setPoints(newPoints);
	};

	const handleMouseDown = (event) => {
		if (event.buttons & 1) {
			const rect = canvasRef.current.getBoundingClientRect();
			const clickPoint: IPoint = {
				x: event.clientX - rect.left,
				y: event.clientY - rect.top
			};

			const pointIndex = points.findIndex(point =>
				Math.hypot(point.x - clickPoint.x, point.y - clickPoint.y) <= pointRadius
			);

			if (pointIndex !== -1) {
				setDraggedPointIndex(pointIndex);
			} else if (pointInsidePolygon(clickPoint, points)) {
				setIsDraggingPolygon(true);
				setCurrentMousePos(clickPoint);
			}
		}
	};

	const handleContextMenuClick = (event) => {
		event.preventDefault();

		const rect = canvasRef.current.getBoundingClientRect();
		const mousePoint: IPoint = {
			x: event.clientX - rect.left,
			y: event.clientY - rect.top
		};

		const foundPoint = points.findIndex(point =>
			Math.hypot(point.x - mousePoint.x, point.y - mousePoint.y) <= pointRadius
		);

		if (foundPoint < 0 || points.length - 1 < 4) {
			return;
		}

		const newPoints = produce(points, draft => {
			draft.splice(foundPoint, 1);
		});

		setPoints(newPoints);
	};

	const handleMouseMove = (event) => {
		const rect = canvasRef.current.getBoundingClientRect();
		const mousePos: IPoint = {
			x: event.clientX - rect.left,
			y: event.clientY - rect.top
		};

		setCurrentMousePos(mousePos);

		if (draggedPointIndex === null && !isDraggingPolygon) {
			const foundPoint = points.findIndex(point =>
				Math.hypot(point.x - mousePos.x, point.y - mousePos.y) <= pointRadius
			);

			setHoveredPointIndex(foundPoint !== -1 ? foundPoint : null);
		} else if (draggedPointIndex !== null) {
			const newPoints = produce(points, draft => {
				draft[ draggedPointIndex ] = mousePos;

				if (isBox) {
					const nextPointIndex = (draggedPointIndex + 1) % points.length;
					const previousPointIndex = draggedPointIndex === 0 ? points.length - 1 : draggedPointIndex - 1;

					if (draggedPointIndex % 2 === 0) {
						draft[ previousPointIndex ].x = mousePos.x;
						draft[ nextPointIndex ].y = mousePos.y;
					} else {
						draft[ previousPointIndex ].y = mousePos.y;
						draft[ nextPointIndex ].x = mousePos.x;
					}
				}
			});

			setPoints(newPoints);
		} else if (isDraggingPolygon) {
			const deltaX = mousePos.x - currentMousePos.x;
			const deltaY = mousePos.y - currentMousePos.y;
			const newPoints = points.map(point => ({
				x: point.x + deltaX,
				y: point.y + deltaY
			}));

			const isOutsideCanvas = newPoints.some(point => {
				return point.x > canvasRef.current.width || point.x < 0
					|| point.y < 0 || point.y > canvasRef.current.height;
			});

			if (!isOutsideCanvas) {
				setPoints(newPoints);
			}
		}
	};

	const handleMouseUp = () => {
		setDraggedPointIndex(null);
		setIsDraggingPolygon(false);
	};

	return (
		<canvas
			className="interactive-polygon"
			ref={ canvasRef }
			onDoubleClick={ handleDoubleClick }
			onMouseDown={ handleMouseDown }
			onMouseMove={ handleMouseMove }
			onMouseUp={ handleMouseUp }
			onContextMenu={ handleContextMenuClick }
		/>
	);
};

export default memo(InteractivePolygon);
