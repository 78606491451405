// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-authority {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.group-authority .scrollable-content {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
  padding: 30px;
}
.group-authority .scrollable-content .container {
  background-color: white;
  border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 20px;
  width: 900px;
}
.group-authority .scrollable-content .container .page-title {
  border-bottom: 1px solid #7e7e7e;
  font-weight: bold;
  margin-bottom: 20px;
  width: 100%;
}
.group-authority .scrollable-content .container .page-title h4 {
  margin-block-end: 5px;
}
.group-authority .scrollable-content .container .cec-checkbox-container {
  display: inline;
}
.group-authority .scrollable-content .container .cec-checkbox-container .cec-checkbox {
  transform: translate(50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/Components/ConfigLayout/User/GroupAuthority/GroupAuthority.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,OAAA;EACA,sBAAA;AADD;AAFA;EAME,aAAA;EACA,OAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;AADF;AATA;EAaG,uBAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,oBAAA;EACA,YAAA;AADH;AAhBA;EAoBI,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAtBA;EA0BK,qBAAA;AADL;AAzBA;EA+BI,eAAA;AAHJ;AA5BA;EAkCK,+BAAA;AAHL","sourcesContent":["@import (reference) \"../../../../Styles/colors\";\n\n.group-authority {\n\tdisplay: flex;\n\tflex: 1;\n\tflex-direction: column;\n\n\t.scrollable-content {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tjustify-content: center;\n\t\toverflow: auto;\n\t\tpadding: 30px;\n\n\t\t.container {\n\t\t\tbackground-color: white;\n\t\t\tborder-radius: 8px;\n\t\t\theight: fit-content;\n\t\t\tpadding: 0 20px 20px;\n\t\t\twidth: 900px;\n\n\t\t\t.page-title {\n\t\t\t\tborder-bottom: 1px solid @light-gray-2;\n\t\t\t\tfont-weight: bold;\n\t\t\t\tmargin-bottom: 20px;\n\t\t\t\twidth: 100%;\n\n\t\t\t\th4 {\n\t\t\t\t\tmargin-block-end: 5px;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.cec-checkbox-container {\n\t\t\t\tdisplay: inline;\n\n\t\t\t\t.cec-checkbox {\n\t\t\t\t\ttransform: translate(50%, -50%);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
