import "Components/ConfigLayout/Camera/EmbeddedOsdSettings/EmbeddedOsdSettings.less";
import { Select } from "@clintonelec/react-storybook";
import { FormEvent, memo, useState } from "react";
import { onOffOptions, deviceNameOptions, colorOptions } from "Data/Objects/EmbeddedOsdOptions";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectEmbeddedOsd, setEmbeddedOsdAction } from "Data/Redux/Slices/Settings/Camera/EmbeddedOsd";
import { IEmbeddedOsdSettingsState } from "Interfaces";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { Link } from "react-router-dom";
import { Colors, DeviceNamePosition } from "Data/Objects/Camera";
import { produce } from "immer";
import { isEqual, merge } from "lodash";

function EmbeddedOsdSettings() {
	const dispatch = useAppDispatch();
	const embeddedOsdSettings = useAppSelector(selectEmbeddedOsd);
	const [ embeddedOsdSettingsDiff, setEmbeddedOsdSettingsDiff ] = useState<Partial<IEmbeddedOsdSettingsState>>({});

	const setEmbeddedOsdSettings = (newSettings: IEmbeddedOsdSettingsState) => {
		dispatch(setEmbeddedOsdAction(newSettings));
	};

	const localEmbeddedOsdSettings = produce(embeddedOsdSettings, draft => {
		merge(draft, embeddedOsdSettingsDiff);
	});

	const { resolution, dateTime, deviceName } = localEmbeddedOsdSettings.cameraInfo;
	const { displayMode, objectColor, ruleColor, eventColor } = localEmbeddedOsdSettings.ai;
	const disabled = isEqual(localEmbeddedOsdSettings, embeddedOsdSettings);

	const handleReset = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmbeddedOsdSettingsDiff({});
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setEmbeddedOsdSettings(localEmbeddedOsdSettings);
		setEmbeddedOsdSettingsDiff({});
	};

	const selectResolution = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.resolution = value === "ON";
		}));
	};

	const selectDateTime = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.dateTime = value === "ON";
		}));
	};

	const selectDeviceName = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.cameraInfo.deviceName = value as DeviceNamePosition;
		}));
	};

	const selectDisplayMode = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.displayMode = value === "ON";
		}));
	};

	const selectObjectColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.objectColor = value as Colors;
		}));
	};

	const selectRuleColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.ruleColor = value as Colors;
		}));
	};

	const selectEventColor = (value: string) => {
		setEmbeddedOsdSettingsDiff(produce(localEmbeddedOsdSettings, draft => {
			draft.ai.eventColor = value as Colors;
		}));
	};

	return (
		<form className="embedded-osd-settings" onReset={ handleReset } onSubmit={ handleSubmit }>
			<div className="scrollable-container">
				<div className="card">
					<div className="page-title">
						<h4>Camera Information</h4>
					</div>
					<div className="form-row">
						<span>Resolution</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectResolution }
							options={ onOffOptions }
							value={ resolution ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<span>Date / Time</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectDateTime }
							options={ onOffOptions }
							value={ dateTime ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<span>Device Name</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectDeviceName }
							options={ deviceNameOptions }
							value={ deviceName }
						/>
					</div>
					<div className="note">
						<span>Note - </span>
						<Link to="/setup/system/management">Go to System Management to set the device name.</Link>
					</div>
				</div>
				<div className="card">
					<div className="page-title">
						<h4>AI OSD</h4>
					</div>
					<div className="form-row">
						<span>Display Mode</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectDisplayMode }
							options={ onOffOptions }
							value={ displayMode ? "ON" : "OFF" }
						/>
					</div>
					<div className="form-row">
						<span>Object Color</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectObjectColor }
							options={ colorOptions }
							value={ objectColor }
						/>
					</div>
					<div className="form-row">
						<span>Rule Color</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectRuleColor }
							options={ colorOptions }
							value={ ruleColor }
						/>
					</div>
					<div className="form-row">
						<span>Event Color</span>
						<Select
							allowClear={ false }
							className="embedded-osd-select"
							onSelect={ selectEventColor }
							options={ colorOptions }
							value={ eventColor }
						/>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(EmbeddedOsdSettings);
