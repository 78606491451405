import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";
import { IMaskSettingsState } from "Interfaces";
import { AppState } from "Data/Redux/Reducers";

const initialState: IMaskSettingsState = {
	maskColor: "red",
	maskEnabled: false,
	zones: [ [], [], [], [], [], [], [], [] ]
};

export const setMaskSettingsReducer = (state: IMaskSettingsState, action: PayloadAction<IMaskSettingsState>) => {
	return action.payload;
};

export const getMaskSettings = (state: IMaskSettingsState) => {
	return state;
};

const maskSlice = createAppSlice({
	name: "mask",
	initialState,
	reducers: {
		setMaskSettingsAction: setMaskSettingsReducer
	},
	selectors: {
		selectMaskSettings: getMaskSettings
	}
});

export default maskSlice;

export const { setMaskSettingsAction } = maskSlice.actions;
export const { selectMaskSettings } = maskSlice.getSelectors<AppState>(state => state.settings.camera.mask);
