import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Event/ActionSettings/ActionLightSettings/ActionLightSettings.less";
import { Checkbox, Input, ISelectOption, Popover, Select } from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IActionState, LightEvents, LightSlice } from "Interfaces";
import { Link } from "react-router-dom";
import { FlickerFrequency } from "Data/Objects/Event";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";

interface IActionLightSettingsProps {
	whiteLight?: boolean;
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function ActionLightSettings(props: IActionLightSettingsProps) {
	const { localActionState: [ localActionSettings, setLocalActionSettings ], whiteLight } = props;
	const lightSlice: LightSlice = whiteLight ? "whiteLight" : "redAndBlueLight";
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const flickerFrequencyOptions: ISelectOption[] = [
		{
			label: FlickerFrequency.LOW,
			value: FlickerFrequency.LOW
		},
		{
			label: FlickerFrequency.MEDIUM,
			value: FlickerFrequency.MEDIUM
		},
		{
			label: FlickerFrequency.HIGH,
			value: FlickerFrequency.HIGH
		}
	];

	if (whiteLight) {
		flickerFrequencyOptions.unshift({
			label: FlickerFrequency.ALWAYS_ON,
			value: FlickerFrequency.ALWAYS_ON
		});
	}

	const updateScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft[ lightSlice ].schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const updateFlickerFrequency = (event: LightEvents) => (flickerFrequency: FlickerFrequency) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft[ lightSlice ][ event ].flickerFrequency = flickerFrequency;
		}));
	};

	const updateDuration = (event: LightEvents) => (duration: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft[ lightSlice ][ event ].duration = +duration;
		}));
	};

	const renderSchedule = (schedule: [ string, boolean ]) => {
		const [ scheduleName, checked ] = schedule;

		return (
			<div className="checkbox-wrapper" key={ scheduleName }>
				<Checkbox
					checked={ checked }
					onChange={ updateScheduleChecked(scheduleName) }
				/>
				<label>{ scheduleName }</label>
			</div>
		);
	};

	return (
		<div className="light-settings">
			<div className="column">
				<div className="form-row header-row">
					<span>Event Type</span>
					<span>Flash Rate</span>
					<span>Duration</span>
				</div>
				<div className="form-row">
					<span>Alarm Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateFlickerFrequency("alarmEvent") }
						options={ flickerFrequencyOptions }
						value={ localActionSettings[ lightSlice ].alarmEvent.flickerFrequency }
					/>
					<Input
						hideCharMessage={ true }
						max={ 60 }
						min={ 1 }
						onUpdate={ updateDuration("alarmEvent") }
						type="number"
						value={ localActionSettings[ lightSlice ].alarmEvent.duration }
					/>
				</div>
				<div className="form-row">
					<span>Motion Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateFlickerFrequency("motionEvent") }
						options={ flickerFrequencyOptions }
						value={ localActionSettings[ lightSlice ].motionEvent.flickerFrequency }
					/>
					<Input
						hideCharMessage={ true }
						max={ 60 }
						min={ 1 }
						onUpdate={ updateDuration("motionEvent") }
						type="number"
						value={ localActionSettings[ lightSlice ].motionEvent.duration }
					/>
				</div>
				<div className="form-row">
					<span>Tamper Event</span>
					<Select
						allowClear={ false }
						onSelect={ updateFlickerFrequency("tamperEvent") }
						options={ flickerFrequencyOptions }
						value={ localActionSettings[ lightSlice ].tamperEvent.flickerFrequency }
					/>
					<Input
						hideCharMessage={ true }
						max={ 60 }
						min={ 1 }
						onUpdate={ updateDuration("tamperEvent") }
						type="number"
						value={ localActionSettings[ lightSlice ].tamperEvent.duration }
					/>
				</div>
				<div className="form-row">
					<span>AI Analytics</span>
					<Select
						allowClear={ false }
						onSelect={ updateFlickerFrequency("aiAnalytics") }
						options={ flickerFrequencyOptions }
						value={ localActionSettings[ lightSlice ].aiAnalytics.flickerFrequency }
					/>
					<Input
						hideCharMessage={ true }
						max={ 60 }
						min={ 1 }
						onUpdate={ updateDuration("aiAnalytics") }
						type="number"
						value={ localActionSettings[ lightSlice ].aiAnalytics.duration }
					/>
				</div>
			</div>
			<div className="column">
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ Object.entries(localActionSettings[ lightSlice ].schedule).map(renderSchedule) }
				</div>
			</div>
		</div>
	);
}

export default memo(ActionLightSettings);
