import { UserPermissions } from "Data/Objects/User";
import { IGroupAuthorityState, IUser } from "Interfaces";

export const DUMMY_ADMIN_USER: IUser = {
	id: "ADMIN_ID",
	userId: "TESTUSER",
	group: "ADMIN",
	notifications: true
};

export const DUMMY_MANAGER_USER: IUser = {
	id: "MANAGER_ID",
	userId: "MANAGER",
	group: "MANAGER",
	notifications: false
};

export const DUMMY_BASIC_USER: IUser = {
	id: "USER_ID",
	userId: "USER",
	email: "test@example.com",
	group: "USER",
	notifications: true
};

const { LISTEN_TO_AUDIO, MIC, SEARCH, EVENT_ACTION_CONTROL, SYSTEM_SETUP } = UserPermissions;

export const DUMMY_GROUP_AUTHORITY: IGroupAuthorityState = {
	user: [ LISTEN_TO_AUDIO, MIC ],
	manager: [ SEARCH, EVENT_ACTION_CONTROL, LISTEN_TO_AUDIO, MIC, SYSTEM_SETUP ]
};
