import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Event/ActionSettings/AlarmOut/AlarmOut.less";
import { Checkbox, Input, ISelectOption, Popover, Select } from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IActionState } from "Interfaces";
import { Link } from "react-router-dom";
import { AlarmOutOperation, Operation } from "Data/Objects/Event";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";

const operationOptions: ISelectOption[] = [
	{
		label: AlarmOutOperation.ALARM_OUT,
		value: AlarmOutOperation.ALARM_OUT
	},
	{
		label: AlarmOutOperation.ON,
		value: AlarmOutOperation.ON
	},
	{
		label: AlarmOutOperation.OFF,
		value: AlarmOutOperation.OFF
	}
];

const typeOptions: ISelectOption[] = [
	{
		label: Operation.NORMALLY_OPEN,
		value: Operation.NORMALLY_OPEN
	},
	{
		label: Operation.NORMALLY_CLOSED,
		value: Operation.NORMALLY_CLOSED
	}
];

const durationOptions: ISelectOption[] = [ 0, 5, 10, 15, 30, 60, 120, 180, 300 ].map(value => ({
	label: value === 0 ? "Transparent" : `${ value } Sec`,
	value
}));

interface IAlarmOutProps {
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function AlarmOut(props: IAlarmOutProps) {
	const { localActionState: [ localActionSettings, setLocalActionSettings ] } = props;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const updateName = (name: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.alarmOut.name = name;
		}));
	};

	const updateScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.alarmOut.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const updateOperation = (operation: AlarmOutOperation) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.alarmOut.operation = operation;
		}));
	};

	const updateType = (type: Operation) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.alarmOut.type = type;
		}));
	};

	const updateDuration = (duration: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.alarmOut.duration = +duration;
		}));
	};

	const renderSchedule = (schedule: [ string, boolean ]) => {
		const [ scheduleName, checked ] = schedule;

		return (
			<div className="checkbox-wrapper" key={ scheduleName }>
				<Checkbox
					checked={ checked }
					onChange={ updateScheduleChecked(scheduleName) }
				/>
				<label>{ scheduleName }</label>
			</div>
		);
	};

	return (
		<div className="alarm-out">
			<div className="column">
				<div className="page-title">
					<h4>Alarm 1</h4>
				</div>
				<div className="form-row">
					<span>Name</span>
					<Input value={ localActionSettings.alarmOut.name } onUpdate={ updateName } />
				</div>
				<div className="form-row">
					<span>Operation</span>
					<Select
						allowClear={ false }
						onSelect={ updateOperation }
						options={ operationOptions }
						value={ localActionSettings.alarmOut.operation }
					/>
				</div>
				<div className="form-row">
					<span>Type</span>
					<Select
						allowClear={ false }
						onSelect={ updateType }
						options={ typeOptions }
						value={ localActionSettings.alarmOut.type }
					/>
				</div>
				<div className="form-row">
					<span>Duration</span>
					<Select
						allowClear={ false }
						onSelect={ updateDuration }
						options={ durationOptions }
						value={ localActionSettings.alarmOut.duration }
					/>
				</div>
			</div>
			<div className="column">
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ Object.entries(localActionSettings.alarmOut.schedule).map(renderSchedule) }
				</div>
			</div>
		</div>
	);
}

export default memo(AlarmOut);
