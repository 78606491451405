import { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IEventNotificationState } from "Interfaces";

const initialState: IEventNotificationState = {
	emailList: [
		"test@email.com",
		"test2@email.com",
		"test3@email.com"
	],
	minimumEmailFrequency: 1,
	includeSnapshotOption: true,
	presetSchedule: {
		always: true,
		weekday: false,
		weekend: false
	}
};

// #region Reducers

const setEventNotificationReducer = (
	state: IEventNotificationState, action: PayloadAction<IEventNotificationState>
) => {
	if (!action.payload) {
		return;
	}

	return action.payload;
};

// #endregion

// #region Selectors

const getEventNotificationSettings = (state: IEventNotificationState) => {
	return state;
};

// #endregion

const eventNotificationSlice = createAppSlice({
	name: "eventNotification",
	initialState,
	reducers: {
		setEventNotificationAction: setEventNotificationReducer
	},
	selectors: {
		selectEventNotificationSettings: getEventNotificationSettings
	}
});

export default eventNotificationSlice;

export const { setEventNotificationAction } = eventNotificationSlice.actions;
export const { selectEventNotificationSettings } = eventNotificationSlice.getSelectors<AppState>(
	state => state.settings.event.eventNotification
);
