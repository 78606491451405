import { PayloadAction } from "@reduxjs/toolkit";
import { DUMMY_IMAGE_SETTINGS } from "Data/Dummy/Camera";
import { AppState } from "Data/Redux/Reducers";
import { createAppSlice } from "Data/Redux/Store";
import { IImageSettingsState } from "Interfaces";

export const setImageReducer = (state: IImageSettingsState, action: PayloadAction<IImageSettingsState>) => {
	return action.payload;
};

export const getImage = (state: IImageSettingsState) => {
	return state;
};

const imageSlice = createAppSlice({
	name: "image",
	initialState: DUMMY_IMAGE_SETTINGS,
	reducers: {
		setImageAction: setImageReducer
	},
	selectors: {
		selectImage: getImage
	}
});

export default imageSlice;

export const { setImageAction } = imageSlice.actions;
export const { selectImage } = imageSlice.getSelectors<AppState>(state => state.settings.camera.image);
