import { ISelectOption } from "@clintonelec/react-storybook";
import { SnmpAuthenticationProtocol, SnmpPrivacyProtocol, SnmpType } from "Interfaces";

export const ipNetworkSettingsWarningMessage = "If for some reason the IP camera fails to acquire an IP address," +
	" you will need to use the admin tool to find this unit.";

export const invalidIpValidityMessage = "Invalid IP Address";

export const invalidPortValueMessage = "Invalid Port Value";

export const cidrRegex = "^(12[0-8]|1[01][0-9]|[1-9]?[0-9])$";

export enum IIpv6RadioSettings {
	OFF = "OFF",
	MANUAL = "MANUAL",
	AUTO = "AUTO"
}

export const snmpTypeOptions: ISelectOption[] = [
	{
		label: "None",
		value: SnmpType.NONE
	},
	{
		label: "SNMP v2",
		value: SnmpType.V2
	},
	{
		label: "SNMP v3",
		value: SnmpType.V3
	}
];

export const snmpAuthProtocolOptions: ISelectOption[] = [
	{
		label: "None",
		value: SnmpAuthenticationProtocol.NONE
	},
	{
		label: "MD5",
		value: SnmpAuthenticationProtocol.MD5
	},
	{
		label: "SHA",
		value: SnmpAuthenticationProtocol.SHA
	}
];

export const snmpPrivacyProtocolOptions: ISelectOption[] = [
	{
		label: "None",
		value: SnmpPrivacyProtocol.NONE
	},
	{
		label: "DES",
		value: SnmpPrivacyProtocol.DES
	},
	{
		label: "AES",
		value: SnmpPrivacyProtocol.AES
	}
];
