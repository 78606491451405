import { MutableRefObject, useEffect } from "react";

export const useResizeCanvas = (canvasRef: MutableRefObject<HTMLCanvasElement>) => {
	useEffect(() => {
		const canvas = canvasRef.current;

		canvas.style.width = "100%";
		canvas.style.height = "100%";
		canvas.height = canvas.offsetHeight;
		canvas.width = canvas.offsetWidth;
	}, [ canvasRef ]);
};
