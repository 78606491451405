import { ChangeEvent, Dispatch, FormEvent, memo, SetStateAction, useRef, useState } from "react";
import { Button, ButtonTypes, Input, Modal } from "@clintonelec/react-storybook";
import "Components/ConfigLayout/Event/ActionSettings/AddAudioModal/AddAudioModal.less";
import { IActionState, IWithChildren } from "Interfaces";
import { produce } from "immer";

interface IAddAudioModalProps extends IWithChildren {
	index?: number;
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function AddAudioModal(props: IAddAudioModalProps) {
	const { localActionState: [ localActionSettings, setLocalActionSettings ], children, index } = props;
	const [ selectedFile, setSelectedFile ] = useState<File>(null);
	const [ visible, setVisible ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);
	const editingFileName = localActionSettings.audio.audioFiles[ index ]?.name.split(".")[ 0 ];
	const editingFileExtension = localActionSettings.audio.audioFiles[ index ]?.name.split(".")[ 1 ];
	const [ fileName, setFileName ] = useState(index ? editingFileName : "");
	const fileSelectRef = useRef<HTMLInputElement>(null);

	const resetState = () => {
		setSelectedFile(null);
		setFileName(index ? editingFileName : "");
		setSubmitted(false);
	};

	const handleSelectedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target?.files?.length === 0) {
			return;
		}

		setSelectedFile(event.target.files[0]);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setSubmitted(true);

		if (!event.currentTarget?.checkValidity()) {
			return;
		}

		const newFileName = `${
			fileName
				? fileName
				: selectedFile.name.split(".")[ 0 ]
		}.${ selectedFile?.name.split(".")[ 1 ] }`;

		const editedFileName = `${ fileName }.${ editingFileExtension }`;

		if (index) {
			setLocalActionSettings(produce(localActionSettings, (draft) => {
				draft.audio.audioFiles.splice(index, 1, { name: editedFileName });
			}));
		} else {
			setLocalActionSettings(produce(localActionSettings, (draft) => {
				draft.audio.audioFiles.push({ name: newFileName });
			}));
		}

		setVisible(false);
	};

	const renderRestrictionMessage = () => {
		if (index >= 0) {
			return;
		}

		return (
			<div className="restriction-message">
				Please upload audio files in .wav and .mp3 formats that are under 512KB.
			</div>
		);
	};

	const renderFileSelector = () => {
		if (index >= 0) {
			return;
		}

		return (
			<div className="file-selector">
				<Button
					onClick={ () => fileSelectRef.current?.click() }
					htmlType="button"
					type={ ButtonTypes.TERTIARY }
				>
					Choose File
				</Button>
				<input
					hidden
					onChange={ handleSelectedFileChange }
					ref={ fileSelectRef }
					type="file"
					accept="audio/mpeg,audio/wav"
				/>
				<Input
					className="file-label"
					disabled
					value={ selectedFile?.name }
				/>
			</div>
		);
	};

	const modalContent = (
		<form className="add-audio-modal-content" onSubmit={ handleSubmit } noValidate>
			{ renderRestrictionMessage() }
			<div className="file-name">
				<span>Name{ index ? "" : " (Optional)" }</span>
				<Input
					value={ fileName }
					onUpdate={ setFileName }
					validityMessage="Filename cannot include certain special characters."
					noValidate={ !submitted }
					validator="^[\w,\s-]+$"
				/>
			</div>
			{ renderFileSelector() }
			<Button
				disabled={ index ? !fileName : !selectedFile }
				fill
				type={ ButtonTypes.TERTIARY }
			>
				{ index >= 0 ? "Submit" : "Upload" }
			</Button>
		</form>
	);

	return (
		<Modal
			title={ index >= 0 ? "Edit Audio" : "Add Audio" }
			modalContent={ modalContent }
			afterClose={ resetState }
			onVisibilityChange={ setVisible }
			visible={ visible }
			width={ 400 }
		>
			{ children }
		</Modal>
	);
}

export default memo(AddAudioModal);
