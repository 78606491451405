import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/AlarmEventSettings/AlarmEventSettings.less";
import { Checkbox, IColumn, Input, ISelectOption, IWithKey, Select, Table } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { selectAlarmEvent, setAlarmEventAction } from "Data/Redux/Slices/Settings/Event/Alarm";
import { isEqual } from "lodash";
import { produce } from "immer";
import { Operation } from "Data/Objects/Event";
import ActionButtons from "Components/ConfigLayout/ActionButtons";

const operationOptions: ISelectOption[] = [
	{
		label: Operation.NORMALLY_OPEN,
		value: Operation.NORMALLY_OPEN
	},
	{
		label: Operation.NORMALLY_CLOSED,
		value: Operation.NORMALLY_CLOSED
	}
];

const column: IColumn[] = [
	{
		dataIndex: "name",
		key: "name",
		sortable: false,
		title: "Name",
		width: 150
	},
	{
		dataIndex: "operation",
		key: "operation",
		sortable: false,
		title: "Operation",
		width: 150
	},
	{
		dataIndex: "alarmOut",
		key: "alarmOut",
		sortable: false,
		title: "Alarm Out",
		width: "max-content"
	},
	{
		dataIndex: "sendImage",
		key: "sendImage",
		sortable: false,
		title: "Send Image",
		width: "max-content"
	},
	{
		dataIndex: "whiteLight",
		key: "whiteLight",
		sortable: false,
		title: "White Light",
		width: "max-content"
	},
	{
		dataIndex: "redBlueLight",
		key: "redBlueLight",
		sortable: false,
		title: "Red & Blue Light",
		width: "max-content"
	},
	{
		dataIndex: "audioAlarm",
		key: "audioAlarm",
		sortable: false,
		title: "Audio Alarm",
		width: "max-content"
	},
	{
		dataIndex: "recording",
		key: "recording",
		sortable: false,
		title: "Recording",
		width: "max-content"
	},
	{
		dataIndex: "email",
		key: "email",
		sortable: false,
		title: "E-Mail",
		width: "max-content"
	}
];

interface IAlarmEventForm extends HTMLFormElement {
	alarmName: HTMLInputElement;
	alarmOut: HTMLInputElement;
	sendImage: HTMLInputElement;
	whiteLight: HTMLInputElement;
	redBlueLight: HTMLInputElement;
	audioAlarm: HTMLInputElement;
	recording: HTMLInputElement;
	email: HTMLInputElement;
}

function AlarmEventSettings() {
	const dispatch = useAppDispatch();
	const alarmEventSettings = useAppSelector(selectAlarmEvent);
	const [ localAlarmEventSettings, setLocalAlarmEventSettings ] = useState(alarmEventSettings);
	const {
		name, operation, alarmOut, sendImage, whiteLight, redBlueLight, audioAlarm, recording, email
	} = localAlarmEventSettings;

	const setAlarmEvent = () => dispatch(setAlarmEventAction(localAlarmEventSettings));
	const disabled = isEqual(localAlarmEventSettings, alarmEventSettings);

	const handleFormChange = (event: FormEvent<IAlarmEventForm>) => {
		const {
			alarmName: { value: newAlarmName },
			alarmOut: { checked: newAlarmOut },
			sendImage: { checked: newSendImage },
			whiteLight: { checked: newWhiteLight },
			redBlueLight: { checked: newRedBlueLight },
			audioAlarm: { checked: newAudioAlarm },
			recording: { checked: newRecording },
			email: { checked: newEmail }
		} = event.currentTarget;

		const newLocalSettings = produce(localAlarmEventSettings, draft => {
			draft.name = newAlarmName;
			draft.alarmOut = newAlarmOut;
			draft.sendImage = newSendImage;
			draft.whiteLight = newWhiteLight;
			draft.redBlueLight = newRedBlueLight;
			draft.audioAlarm = newAudioAlarm;
			draft.recording = newRecording;
			draft.email = newEmail;
		});

		setLocalAlarmEventSettings(newLocalSettings);
	};

	const handleFormReset = (event: FormEvent<IAlarmEventForm>) => {
		event.preventDefault();

		setLocalAlarmEventSettings(alarmEventSettings);
	};

	const handleFormSubmit = (event: FormEvent<IAlarmEventForm>) => {
		event.preventDefault();

		if (event.currentTarget?.checkValidity()) {
			setAlarmEvent();
		}
	};

	const selectOperation = (value: Operation) => {
		setLocalAlarmEventSettings(produce(localAlarmEventSettings, draft => {
			draft.operation = value;
		}));
	};

	const tableData: IWithKey[] = [
		{
			key: "alarmEventName",
			name: <Input name="alarmName" value={ name } />,
			operation: <Select
				allowClear={ false }
				onSelect={ selectOperation }
				options={ operationOptions }
				value={ operation }
			/>,
			alarmOut: <Checkbox checked={ alarmOut } name="alarmOut" />,
			sendImage: <Checkbox checked={ sendImage } name="sendImage" />,
			whiteLight: <Checkbox checked={ whiteLight } name="whiteLight" />,
			redBlueLight: <Checkbox checked={ redBlueLight } name="redBlueLight" />,
			audioAlarm: <Checkbox checked={ audioAlarm } name="audioAlarm" />,
			recording: <Checkbox checked={ recording } name="recording" />,
			email: <Checkbox checked={ email } name="email" />
		}
	];

	return (
		<form
			className="alarm-event-settings-form"
			noValidate
			onChange={ handleFormChange }
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="alarm-settings-container">
					<div className="alarm-settings">
						<div className="card">
							<div className="header">
								<h4>Alarm Event Settings</h4>
							</div>
							<div className="alarm-settings-banner">
								<span>Notification</span>
								<span>&nbsp;-&nbsp;</span>
								<span>
									Actions and notifications require detailed settings on each setting page to work.
								</span>
							</div>
							<Table
								columns={ column }
								data={ tableData }
							/>
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ disabled } />
		</form>
	);
}

export default memo(AlarmEventSettings);
