import { AspectMaintainer, Select } from "@clintonelec/react-storybook";
import {
	BacklightCompensation, backlightCompensationOptions, DefogControl, defogControlOptions, DigitalNoiseReduction,
	digitalNoiseReductionOptions, EnhancementActivation, enhancementActivationOptions, ExposureMode,
	HighlightCompensation, highlightCompensationOptions, onOffOptions
} from "Data/Objects/Camera";
import { produce } from "immer";
import { IImageSettingsState } from "Interfaces";
import { Dispatch, Fragment, memo, SetStateAction } from "react";

interface IEnhancementProps {
	localSettings: IImageSettingsState;
	renderPopover: (message: string) => JSX.Element;
	setLocalSettings: Dispatch<SetStateAction<IImageSettingsState>>;
}

function Enhancement(props: IEnhancementProps) {
	const { localSettings, setLocalSettings, renderPopover } = props;
	const {
		wideDynamicRange, backlightCompensation, highlightCompensation, digitalNoiseReduction, defogControl,
		enhancementActivation
	} = localSettings.enhancement;

	const isManualExposure = localSettings.exposure.exposureMode === ExposureMode.MANUAL;

	const selectWideDynamicRange = (value: string) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.wideDynamicRange = value === "ON";
		}));
	};

	const selectBacklightCompensation = (value: BacklightCompensation) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.backlightCompensation = value;
		}));
	};

	const selectHighlightCompensation = (value: HighlightCompensation) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.highlightCompensation = value;
		}));
	};

	const selectDigitalNoiseReduction = (value: DigitalNoiseReduction) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.digitalNoiseReduction = value;
		}));
	};

	const selectDefogControl = (value: DefogControl) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.defogControl = value;
		}));
	};

	const selectEnhancementActivation = (value: EnhancementActivation) => {
		setLocalSettings(produce(localSettings, draft => {
			draft.enhancement.enhancementActivation = value;
		}));
	};

	const renderInfoIcon = () => {
		if (isManualExposure) {
			return renderPopover("To use this setting, go to Exposure tab and change 'Exposure Mode'");
		}
	};

	return (
		<Fragment>
			<div className="content-left">
				<AspectMaintainer>
					<div className="camera-snapshot"></div>
				</AspectMaintainer>
			</div>
			<div className="content-right">
				<div className="form-row">
					<span>Wide Dynamic Range</span>
					{ renderInfoIcon() }
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ isManualExposure }
						onSelect={ selectWideDynamicRange }
						options={ onOffOptions }
						value={ wideDynamicRange ? "ON" : "OFF" }
					/>
				</div>
				<div className="form-row">
					<span>Backlight Compensation</span>
					{ renderInfoIcon() }
					<Select
						allowClear={ false }
						className="image-select"
						disabled={
							isManualExposure
							|| localSettings.enhancement.highlightCompensation !== HighlightCompensation.OFF
						}
						onSelect={ selectBacklightCompensation }
						options={ backlightCompensationOptions }
						value={ backlightCompensation }
					/>
				</div>
				<div className="form-row">
					<span>Highlight Compensation</span>
					{ renderInfoIcon() }
					<Select
						allowClear={ false }
						className="image-select"
						disabled={
							isManualExposure
							|| localSettings.enhancement.backlightCompensation !== BacklightCompensation.OFF
						}
						onSelect={ selectHighlightCompensation }
						options={ highlightCompensationOptions }
						value={ highlightCompensation }
					/>
				</div>
				<div className="form-row">
					<span>Digital Noise Reduction</span>
					<Select
						allowClear={ false }
						className="image-select"
						onSelect={ selectDigitalNoiseReduction }
						options={ digitalNoiseReductionOptions }
						value={ digitalNoiseReduction }
					/>
				</div>
				<div className="form-row">
					<span>Defog Control</span>
					<Select
						allowClear={ false }
						className="image-select"
						disabled={ wideDynamicRange }
						onSelect={ selectDefogControl }
						options={ defogControlOptions }
						value={ defogControl }
					/>
				</div>
				<div className="form-row">&nbsp;</div>
				<div className="form-row">
					<span>Activation</span>
					<Select
						allowClear={ false }
						className="image-select"
						onSelect={ selectEnhancementActivation }
						options={ enhancementActivationOptions }
						value={ enhancementActivation }
					/>
				</div>
			</div>
		</Fragment>
	);
}

export default memo(Enhancement);
