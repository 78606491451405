import { combineSlices } from "@reduxjs/toolkit";
import systemEventSlice from "Data/Redux/Slices/Settings/Event/System";
import motionEventSlice from "Data/Redux/Slices/Settings/Event/Motion";
import tamperEventSlice from "Data/Redux/Slices/Settings/Event/Tamper";
import analyticsEventSlice from "Data/Redux/Slices/Settings/Event/Analytics";
import networkLossEventSlice from "Data/Redux/Slices/Settings/Event/NetworkLoss";
import actionSlice from "Data/Redux/Slices/Settings/Event/Action";
import alarmEventSlice from "Data/Redux/Slices/Settings/Event/Alarm";
import eventNotificationSlice from "Data/Redux/Slices/Settings/Event/Notification";

export default combineSlices(
	motionEventSlice, tamperEventSlice, networkLossEventSlice, analyticsEventSlice, actionSlice, alarmEventSlice,
	systemEventSlice, eventNotificationSlice
);
