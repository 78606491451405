import { createAppSlice } from "Data/Redux/Store";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICodecSettingsState } from "Interfaces";
import { DUMMY_CODEC_SETTINGS } from "Data/Dummy/Camera";
import { AppState } from "Data/Redux/Reducers";

export const setCodecReducer = (state: ICodecSettingsState, action: PayloadAction<ICodecSettingsState>) => {
	return action.payload;
};

export const getCodec = (state: ICodecSettingsState) => {
	return state;
};

const codecSlice = createAppSlice({
	name: "codec",
	initialState: DUMMY_CODEC_SETTINGS,
	reducers: {
		setCodecAction: setCodecReducer
	},
	selectors: {
		selectCodec: getCodec
	}
});

export default codecSlice;

export const { setCodecAction } = codecSlice.actions;
export const { selectCodec } = codecSlice.getSelectors<AppState>(state => state.settings.camera.codec);
