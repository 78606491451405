import { FormEvent, memo, useState } from "react";
import "Components/ConfigLayout/Event/ActionSettings/ActionSettings.less";
import { Tabs } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import ActionButtons from "Components/ConfigLayout/ActionButtons";
import { isEqual } from "lodash";
import { selectActionSettings, setActionSettingsAction } from "Data/Redux/Slices/Settings/Event/Action";
import AlarmOut from "Components/ConfigLayout/Event/ActionSettings/AlarmOut";
import SendImage from "Components/ConfigLayout/Event/ActionSettings/SendImage";
import ActionLightSettings from "Components/ConfigLayout/Event/ActionSettings/ActionLightSettings";
import Audio from "Components/ConfigLayout/Event/ActionSettings/Audio";
import { produce } from "immer";

function ActionSettings() {
	const dispatch = useAppDispatch();
	const actionSettings = useAppSelector(selectActionSettings);
	const localActionState = useState(actionSettings);
	const [ localActionSettings, setLocalActionSettings ] = localActionState;
	const setActionSettings = () => dispatch(setActionSettingsAction(localActionSettings));

	const handleFormSubmit = (event: FormEvent) => {
		event.preventDefault();

		setActionSettings();
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.password = "";
		}));
	};

	const handleFormReset = (event: FormEvent) => {
		event.preventDefault();

		setLocalActionSettings(actionSettings);
	};

	const panes = [
		{
			title: "Alarm Out",
			content: <AlarmOut localActionState={ localActionState } />
		},
		{
			title: "Send Image",
			content: <SendImage localActionState={ localActionState } />
		},
		{
			title: "White Light",
			content: <ActionLightSettings localActionState={ localActionState } whiteLight />
		},
		{
			title: "Red & Blue Light",
			content: <ActionLightSettings localActionState={ localActionState } />
		},
		{
			title: "Audio",
			content: <Audio localActionState={ localActionState } />
		}
	];

	return (
		<form
			className="action-settings-form"
			noValidate
			onReset={ handleFormReset }
			onSubmit={ handleFormSubmit }
		>
			<div className="scrollable-container">
				<div className="action-settings-container">
					<div className="action-settings">
						<div className="card">
							<div className="page-title">
								<h4>Action Settings</h4>
							</div>
							<Tabs panes={ panes } />
						</div>
					</div>
				</div>
			</div>
			<ActionButtons disabled={ isEqual(localActionSettings, actionSettings) } />
		</form>
	);
}

export default memo(ActionSettings);
