export interface ISetSystemSecurityPayload {
	autoLogout: boolean;
	autoLogoutDuration: number;
}

export interface ISnmpTrapConfiguration {
	trapEnabled?: boolean;
	trapAddress?: string;
	trapCommunity?: string;
	trapOptions?: {
		authFailure: boolean;
		networkConnection: boolean;
		otherOption: boolean;
	};
}

export interface ISnmpV2Options extends ISnmpTrapConfiguration {
	readCommunity?: string;
	writeCommunity?: string;
}

export interface ISnmpV3Options extends ISnmpTrapConfiguration {
	username: string;
	authProtocol: SnmpAuthenticationProtocol;
	authPassword: string;
	privacyProtocol: SnmpPrivacyProtocol;
	privacyPassword: string;
}

export enum SnmpType {
	NONE = "none",
	V2 = "v2",
	V3 = "v3"
}

export enum SnmpAuthenticationProtocol {
	NONE = "None",
	MD5 = "MD5",
	SHA = "SHA"
}

export enum SnmpPrivacyProtocol {
	NONE = "None",
	DES = "DES",
	AES = "AES"
}
