import { IPoint } from "Interfaces";
import { memo, useEffect, useRef } from "react";
import "Components/ConfigLayout/Camera/MaskSettings/MaskedArea/MaskedArea.less";
import { useResizeCanvas } from "Data/Utils/Effects";

interface IMaskedAreaProps {
	color: string;
	zones: IPoint[][];
}

function MaskedArea(props: IMaskedAreaProps) {
	const { color, zones } = props;
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useResizeCanvas(canvasRef);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas?.getContext("2d");

		context.fillStyle = color;
		context.clearRect(0, 0, canvas.width, canvas.height);

		zones.forEach(points => {
			if (points.length > 1) {
				context.beginPath();
				context.moveTo(points[0].x, points[0].y);
				points.forEach(point => context.lineTo(point.x, point.y));
				context.closePath();
				context.fill();
			}
		});
	}, [ color, zones ]);

	return (
		<canvas className="masked-area" ref={ canvasRef } />
	);
}

export default memo(MaskedArea);
