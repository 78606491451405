import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import "Components/ConfigLayout/Event/ActionSettings/SendImage/SendImage.less";
import { Checkbox, Input, ISelectOption, Popover, Select } from "@clintonelec/react-storybook";
import { produce } from "immer";
import { IActionState } from "Interfaces";
import { Link } from "react-router-dom";
import Icon, { IconSize, IconWeight } from "Components/Global/Icon";

const frequencyOptions: ISelectOption[] = [ 0, 5, 10, 15, 30, 60, 120, 180, 300 ].map(value => ({
	label: value === 0 ? "Immediately" : `${ value } Sec`,
	value
}));

interface ISendImageProps {
	localActionState: [ IActionState, Dispatch<SetStateAction<IActionState>> ];
}

function SendImage(props: ISendImageProps) {
	const { localActionState: [ localActionSettings, setLocalActionSettings ] } = props;
	const link = (
		<div>
			Go to <Link to="/setup/system/schedule">Schedule Preset</Link> to create a new preset.
		</div>
	);

	const updateFtpReceiver = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.receiver = value;
		}));
	};

	const updateHost = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.host = value;
		}));
	};

	const updatePort = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.port = +value;
		}));
	};

	const updateBasePath = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.basePath = value;
		}));
	};

	const updateIdPassRequired = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.idPassRequired = event.currentTarget.checked;
		}));
	};

	const updateId = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.id = value;
		}));
	};

	const updatePassword = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.password = value;
		}));
	};

	const updateFtpFilePrefix = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.filePrefix = value;
		}));
	};

	const updateFtpLog = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.log = event.currentTarget.checked;
		}));
	};

	const updateFtpFrequency = (duration: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.frequency = +duration;
		}));
	};

	const updateFtpScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.ftpSettings.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const updateSdReceiver = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.sdSettings.receiver = value;
		}));
	};

	const updateSdFilePrefix = (value: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.sdSettings.filePrefix = value;
		}));
	};

	const updateSdFrequency = (duration: string) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.sdSettings.frequency = +duration;
		}));
	};

	const updateSdLog = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.sdSettings.log = event.currentTarget.checked;
		}));
	};

	const updateSdScheduleChecked = (scheduleName: string) => (event: ChangeEvent<HTMLInputElement>) => {
		setLocalActionSettings(produce(localActionSettings, (draft) => {
			draft.sendImage.sdSettings.schedule[ scheduleName ] = event.currentTarget.checked;
		}));
	};

	const renderSchedule = (updater: (scheduleName: string) => (event: ChangeEvent) => void) => {
		return function Schedule(schedule: [ string, boolean ]) {
			const [ scheduleName, checked ] = schedule;

			return (
				<div className="checkbox-wrapper" key={ scheduleName }>
					<Checkbox
						checked={ checked }
						onChange={ updater(scheduleName) }
					/>
					<label>{ scheduleName }</label>
				</div>
			);
		};
	};

	const ftpSchedule = Object
		.entries(localActionSettings.sendImage.ftpSettings.schedule)
		.map(renderSchedule(updateFtpScheduleChecked));

	const sdSchedule = Object
		.entries(localActionSettings.sendImage.sdSettings.schedule)
		.map(renderSchedule(updateSdScheduleChecked));

	return (
		<div className="send-image">
			<div className="column">
				<div className="page-title">
					<h4>FTP</h4>
				</div>
				<div className="form-row">
					<span>Receiver</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.receiver }
						onUpdate={ updateFtpReceiver }
					/>
				</div>
				<div className="form-row">
					<span>Host / IP</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.host }
						onUpdate={ updateHost }
					/>
				</div>
				<div className="form-row">
					<span>Port</span>
					<Input
						hideCharMessage={ true }
						max={ 65535 }
						min={ 1 }
						onUpdate={ updatePort }
						type="number"
						value={ localActionSettings.sendImage.ftpSettings.port }
					/>
				</div>
				<div className="form-row">
					<span>Base Path</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.basePath }
						onUpdate={ updateBasePath }
					/>
				</div>
				<div className="form-row">
					<span>ID/Password Required</span>
					<Checkbox
						checked={ localActionSettings.sendImage.ftpSettings.idPassRequired }
						onChange={ updateIdPassRequired }
					/>
				</div>
				<div className="form-row">
					<span>ID</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.id }
						onUpdate={ updateId }
						disabled={ !localActionSettings.sendImage.ftpSettings.idPassRequired }
					/>
				</div>
				<div className="form-row">
					<span>Password</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.password }
						onUpdate={ updatePassword }
						disabled={ !localActionSettings.sendImage.ftpSettings.idPassRequired }
						password
						placeholder="****"
					/>
				</div>
				<div className="form-row">
					<span>File Prefix</span>
					<Input
						value={ localActionSettings.sendImage.ftpSettings.filePrefix }
						onUpdate={ updateFtpFilePrefix }
					/>
				</div>
				<div className="form-row">
					<span>Min. Frequency</span>
					<Select
						allowClear={ false }
						onSelect={ updateFtpFrequency }
						options={ frequencyOptions }
						value={ localActionSettings.sendImage.ftpSettings.frequency }
					/>
				</div>
				<div className="form-row">
					<span>Log</span>
					<Checkbox
						checked={ localActionSettings.sendImage.ftpSettings.log }
						onChange={ updateFtpLog }
					/>
				</div>
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ ftpSchedule }
				</div>
			</div>
			<div className="column">
				<div className="page-title">
					<h4>SD Card</h4>
				</div>
				<div className="form-row">
					<span>Receiver</span>
					<Input
						value={ localActionSettings.sendImage.sdSettings.receiver }
						onUpdate={ updateSdReceiver }
					/>
				</div>
				<div className="form-row">
					<span>File Prefix</span>
					<Input
						value={ localActionSettings.sendImage.sdSettings.filePrefix }
						onUpdate={ updateSdFilePrefix }
					/>
				</div>
				<div className="form-row">
					<span>Min. Frequency</span>
					<Select
						allowClear={ false }
						onSelect={ updateSdFrequency }
						options={ frequencyOptions }
						value={ localActionSettings.sendImage.sdSettings.frequency }
					/>
				</div>
				<div className="form-row">
					<span>Log</span>
					<Checkbox
						checked={ localActionSettings.sendImage.sdSettings.log }
						onChange={ updateSdLog }
					/>
				</div>
				<div className="schedule-title">
					<h4>Schedule</h4>
					<Popover content={ link }>
						<Icon name="circle-info" iconWeight={ IconWeight.LIGHT } size={ IconSize.SMALLEST } />
					</Popover>
				</div>
				<div className="schedules">
					{ sdSchedule }
				</div>
			</div>
		</div>
	);
}

export default memo(SendImage);
